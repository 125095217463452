import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { HYDRATE, createWrapper } from "next-redux-wrapper";
import { all, call } from "redux-saga/effects";
import logger from "redux-logger";
import { accountReducer, ACCOUNT } from "@store/account/accountSlice";
import { accountSaga } from "@store/account/accountSaga";
import { categoryReducer, CATEGORY } from "@store/category/categorySlice";
import { categorySaga } from "@store/category/categorySaga";
import { keywordReducer, KEYWORD } from "@store/keyword/keywordSlice";
import { keywordSaga } from "@store/keyword/keywordSaga";
import { challengeReducer, CHALLENGE } from "@store/challenge/challengeSlice";
import { challengeSaga } from "@store/challenge/challengeSaga";
import { clayfulReducer, CLAYFUL } from "@store/clayful/clayfulSlice";
import { clayfulSaga } from "@store/clayful/clayfulSaga";
import { userPetReducer, USERPET } from "./userPet/userPetSlice";
import { userPetSaga } from "@store/userPet/userPetSaga";
import { petKindReducer, PETKIND } from "@store/petKind/petKindSlice";
import { petKindSaga } from "@store/petKind/petKindSaga";
import {
  packageChallengeReducer,
  PACKAGECHALLENGE,
} from "@store/packageChallenge/packageChallengeSlice";
import { packageChallengeSaga } from "@store/packageChallenge/packageChallengeSaga";
import { dashboardReducer, DASHBOARD } from "@store/dashboard/dashboardSlice";
import { dashboardSaga } from "@store/dashboard/dashboardSaga";
import { answerReducer, ANSWER } from "@store/answer/answerSlice";
import { answerSaga } from "@store/answer/answerSaga";
import { commonCodeReducer, COMMONCODE } from "@store/commonCode/commonCodeSlice";
import { commonCodeSaga } from "@store/commonCode/commonCodeSaga";
import {
  packageInChallengeReducer,
  PACKAGEINCHALLENGE,
} from "@store/packageInChallenge/packageInChallengeSlice";
import { packageInChallengeSaga } from "@store/packageInChallenge/packageInChallengeSaga";
import { knolloStoreReducer, KNOLLOSTORE } from "@store/knolloStore/knolloStoreSlice";
import { knolloStoreSaga } from "@store/knolloStore/knolloStoreSaga";
import { pointReducer, POINT } from "@store/point/pointSlice";
import { pointSaga } from "@store/point/pointSaga";
import { pointBackendReducer, POINTBACKEND } from "@store/pointBackend/pointBackendSlice";
import { pointBackendSaga } from "@store/pointBackend/pointBackendSaga";
import { userPlayReducer, USERPLAY } from "./userPlay/userPlaySlice";
import { userPlaySaga } from "@store/userPlay/userPlaySaga";
import { playDashboardReducer, PLAYDASHBOARD } from "./playDashboard/playDashboardSlice";
import { playDashboardSaga } from "@store/playDashboard/playDashboardSaga";
import { eventReducer, EVENT } from "./event/eventSlice";
import { eventSaga } from "./event/eventSaga";
import { csFormReducer, CSFORM } from "./csb/form/csFormSlice";
import { csFormSaga } from "./csb/form/csFormSaga";
import { csFormRespReducer, CSFORMRESP } from "./csm/formResp/csFormRespSlice";
import { csFormRespSaga } from "./csm/formResp/csFormRespSaga";
import { csFormSectionReducer, CSFORMSECTION } from "./csb/section/csFormSectionSlice";
import { csFormSectionSaga } from "./csb/section/csFormSectionSaga";
import { csClinicReducer, CSCLINIC } from "./csm/clinic/csClinicSlice";
import { csClinicSaga } from "./csm/clinic/csClinicSaga";
import { csClinicResultReducer, CSCLINICRESULT } from "./csm/clinic/result/csClinicResultSlice";
import { csClinicResultSaga } from "./csm/clinic/result/csClinicResultSaga";
import { ATTACHMENT, attachmentReducer } from "./attachment/attachmentSlice";
import { attachmentSaga } from "./attachment/attachmentSaga";
import { orderSaga } from "./order/orderSaga";
import { ORDER, orderReducer } from "./order/orderSlice";
import { SNACKBAR, snackbarReducer } from "./snackbar/snackbarSlice";
import { SALESCHECK, salescheckReducer } from "./salescheck/salescheckSlice";
import { salescheckSaga } from "./salescheck/salescheckSaga";
import { CLINIC, clinicReducer } from "./clinic/clinicSlice";
import { clinicSaga } from "./clinic/clinicSaga";
import { smsSaga } from "./sms/smsSaga";
import { SMS, smsReducer } from "./sms/smsSlice";
import { CARTTRACKING, carttrackingReducer } from "./carttracking/carttrackingSlice";
import { carttrackingSaga } from "./carttracking/carttrackingSaga";
import { REWARD, rewardReducer } from "./reward/rewardSlice";
import { rewardSaga } from "./reward/rewardSaga";
import { RECENTTRACKING, recenttrackingReducer } from "./recenttracking/recenttrackingSlice";
import { recentTrackingSaga } from "./recenttracking/recenttrackingSaga";
import {
  FREQUENCYTRACKING,
  frequencytrackingReducer,
} from "./frequencytracking/frequencytrackingSlice";
import { frequencyTrackingSaga } from "./frequencytracking/frequencytrackingSaga";
const devMode = process.env.NEXT_PUBLIC_EXEC_MODE === "dev";

function* rootSaga() {
  yield all([
    call(accountSaga),
    call(categorySaga),
    call(keywordSaga),
    call(challengeSaga),
    call(clayfulSaga),
    call(userPetSaga),
    call(petKindSaga),
    call(packageChallengeSaga),
    call(dashboardSaga),
    call(answerSaga),
    call(commonCodeSaga),
    call(packageInChallengeSaga),
    call(knolloStoreSaga),
    call(pointSaga),
    call(pointBackendSaga),
    call(userPlaySaga),
    call(playDashboardSaga),
    call(eventSaga),
    call(csFormSaga),
    call(csFormRespSaga),
    call(csFormSectionSaga),
    call(csClinicSaga),
    call(csClinicResultSaga),
    call(attachmentSaga),
    call(orderSaga),
    call(salescheckSaga),
    call(clinicSaga),
    call(smsSaga),
    call(carttrackingSaga),
    call(rewardSaga),
    call(recentTrackingSaga),
    call(frequencyTrackingSaga),
  ]);
}

export const rootReducer = combineReducers({
  index: (state = {}, action) => {
    switch (action.type) {
      case HYDRATE:
        return {
          ...state,
          ...action.payload,
        };

      default:
        return state;
    }
  },
  [ACCOUNT]: accountReducer,
  [CATEGORY]: categoryReducer,
  [KEYWORD]: keywordReducer,
  [CHALLENGE]: challengeReducer,
  [CLAYFUL]: clayfulReducer,
  [USERPET]: userPetReducer,
  [PETKIND]: petKindReducer,
  [PACKAGECHALLENGE]: packageChallengeReducer,
  [DASHBOARD]: dashboardReducer,
  [ANSWER]: answerReducer,
  [COMMONCODE]: commonCodeReducer,
  [PACKAGEINCHALLENGE]: packageInChallengeReducer,
  [KNOLLOSTORE]: knolloStoreReducer,
  [POINT]: pointReducer,
  [POINTBACKEND]: pointBackendReducer,
  [USERPLAY]: userPlayReducer,
  [PLAYDASHBOARD]: playDashboardReducer,
  [EVENT]: eventReducer,
  [CSFORM]: csFormReducer,
  [CSFORMRESP]: csFormRespReducer,
  [CSFORMSECTION]: csFormSectionReducer,
  [CSCLINIC]: csClinicReducer,
  [CSCLINICRESULT]: csClinicResultReducer,
  [ATTACHMENT]: attachmentReducer,
  [ORDER]: orderReducer,
  [SNACKBAR]: snackbarReducer,
  [SALESCHECK]: salescheckReducer,
  [CLINIC]: clinicReducer,
  [SMS]: smsReducer,
  [CARTTRACKING]: carttrackingReducer,
  [REWARD]: rewardReducer,
  [RECENTTRACKING]: recenttrackingReducer,
  [FREQUENCYTRACKING]: frequencytrackingReducer,
});

let store;

export const getStore = () => store;

const sagaMiddleware = createSagaMiddleware();

const makeStore = () => {
  store = configureStore({
    reducer: rootReducer,
    devTools: devMode,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
  });

  store.sagaTask = sagaMiddleware.run(rootSaga);
  return store;
};

const wrapper = createWrapper(makeStore, {
  debug: devMode,
});

export default wrapper;
