import { takeLatest, put, all, fork, select, delay, call } from "redux-saga/effects";
import { clinicAction } from "./clinicSlice";
import Swal from "sweetalert2";
import * as clinicApi from "@store/clinic/clinicApi";
import createAsyncSaga from "@store/saga.util";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { isAdmin } from "@utils/helper";

function* getClinicProductSaga(action) {
  try {
    const { userToken, clinicId, router } = action.payload;
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const checkAdmin = isAdmin(userToken);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield clinicApi.getClinicProduct({ userToken, clinicId, store_current_id });
    yield put(clinicAction.getClinicProductSuccess(response.data));
    if (router) {
      router.push(`/clinic/products/${clinicId}`);
    }
  } catch (error) {
    yield put(clinicAction.getClinicProductError(error));
  }
}

function* getClinicProductListSaga(action) {
  try {
    const { userToken, page, size, sort, type } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(userToken).storeInfo.id;
    const itemListSearchOption = yield select((state) => state.clinic.itemListSearchOption);
    let response = null;
    if (type === "search") {
      response = yield clinicApi.getClinicProductListSearch({
        userToken,
        store_current_id: store_current_id,
        searchType: itemListSearchOption.filterOption,
        searchText: itemListSearchOption.searchText,
      });
    } else
      response = yield clinicApi.getClinicProductList({
        userToken,
        store_current_id: store_current_id,
        page,
        size,
        sort,
      });
    yield put(clinicAction.getClinicProductListSuccess(response.data));
    yield put(clinicAction.setGetClinicProductListPageable(response.data.pageable));
  } catch (error) {
    yield put(clinicAction.getClinicProductListError(error));
  }
}
function* updateClinicProductSaga(action) {
  try {
    const { userToken, data, router } = action.payload;
    const checkAdmin = isAdmin(action.payload.userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield call(clinicApi.updateClinicProduct, {
      userToken,
      store_current_id,
      data,
    });

    yield put(clinicAction.updateClinicProductSuccess(response));

    if (response.status_code === 1) {
      Swal.fire({
        title: "수정되었습니다.",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          router.replace("/clinic/itemlist");
        }
      });
    } else {
      Swal.fire({
        title: "오류가 발생하였습니다.",
        text: response.message ?? "",
        icon: "error",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "오류가 발생하였습니다.",
      text: error.response.data?.message ?? "",
      icon: "error",
    });
    yield put(clinicAction.updateClinicProductError(error));
  }
}

function* copyClinicProductSaga(action) {
  try {
    const { userToken, clinicId, router } = action.payload;
    const checkAdmin = isAdmin(action.payload.userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield clinicApi.copyClinicProduct({ userToken, store_current_id, clinicId });
    const itemListSearchOption = yield select((state) => state.clinic.itemListSearchOption);
    yield put(clinicAction.copyClinicProductSuccess(response.data));

    if (response.status_code === 1) {
      const result = yield Swal.fire({
        title: "복사되었습니다.",
        icon: "success",
        draggable: true,
      });

      if (result.isConfirmed) {
        yield put(
          clinicAction.getClinicProductList({
            userToken,
            store_current_id,
            page: itemListSearchOption.page,
            size: itemListSearchOption.size,
            sort: itemListSearchOption.sortOption,
          })
        );
      }
    }
  } catch (error) {
    Swal.fire({
      title: "오류가 발생하였습니다.",
      icon: "error",
    });
    yield put(clinicAction.copyClinicProductError(error));
  }
}

function* createClinicProductSaga(action) {
  try {
    const { userToken, clinicData, router } = action.payload;
    const checkAdmin = isAdmin(action.payload.userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield clinicApi.createClinicProduct({
      userToken,
      clinicData,
      store_current_id,
    });
    if (response.status_code === 1) {
      yield put(clinicAction.createClinicProductSuccess(response.data));
      const result = yield Swal.fire({
        title: "생성되었습니다.",
        icon: "success",
        draggable: true,
      });

      if (result.isConfirmed) {
        router.push("/clinic/itemlist");
      }
    } else {
      Swal.fire({
        title: "오류가 발생하였습니다.",
        text: response.message ?? "",
        icon: "error",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "오류가 발생하였습니다.",
      text: error,
      icon: "error",
    });
    yield put(clinicAction.createClinicProductError(error));
  }
}

function* deleteClinicProductSaga(action) {
  try {
    const { userToken, clinicId, router } = action.payload;
    const checkAdmin = isAdmin(action.payload.userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield clinicApi.deleteClinicProduct({ userToken, store_current_id, clinicId });
    const itemListSearchOption = yield select((state) => state.clinic.itemListSearchOption);
    yield put(clinicAction.deleteClinicProductSuccess(response.data));
    if (response.status_code === 1) {
      const result = yield Swal.fire({
        title: "삭제되었습니다.",
        icon: "success",
        draggable: true,
      });

      if (result.isConfirmed) {
        yield put(
          clinicAction.getClinicProductList({
            userToken,
            store_current_id,
            page: itemListSearchOption.page,
            size: itemListSearchOption.size,
            sort: itemListSearchOption.sortOption,
          })
        );
      }
    } else {
      Swal.fire({
        title: "오류가 발생하였습니다.",
        text: response.message ?? "",
        icon: "error",
      });
    }
  } catch (error) {
    Swal.fire({
      title: "오류가 발생하였습니다.",
      icon: "error",
    });
    yield put(clinicAction.deleteClinicProductError(error));
  }
}

function* updateClinicProductOrderSaga(action) {
  try {
    const { userToken, reorderedItems } = action.payload;
    const checkAdmin = isAdmin(action.payload.userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const tokenInfo = jwtDecode(userToken);
    const { data: getClinicProductData } = yield select(
      (state) => state.clinic.getClinicProductList
    );
    const filterdData = reorderedItems.map((item, index) => {
      return { [item.id]: index + 1 };
    });
    const params = {
      // store_id: tokenInfo.storeInfo.id,
      item_sort: filterdData,
    };
    yield put(
      clinicAction.getClinicProductListSuccess({
        ...getClinicProductData,
        content: reorderedItems,
      })
    );
    const response = yield clinicApi.updateClinicProductOrder({
      userToken,
      store_current_id,
      data: params,
    });
    yield put(clinicAction.updateClinicProductOrderSuccess(response.data));
    if (response.status_code === 1) {
      Swal.fire({
        title: "품목 순서가 변경되었습니다.",
        icon: "success",
        draggable: true,
      });
    } else {
      Swal.fire({
        title: "오류가 발생하였습니다.",
        icon: "error",
      });
      window.location.reload();
    }
  } catch (error) {
    Swal.fire({
      title: "오류가 발생하였습니다.",
      icon: "error",
    });
    window.location.reload();
    yield put(clinicAction.updateClinicProductOrderError(error));
  }
}

function* updateClinicProductDisplaySaga(action) {
  try {
    const { userToken, id, item } = action.payload;
    const checkAdmin = isAdmin(action.payload.userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield clinicApi.updateClinicProductDisplay({
      userToken,
      store_current_id,
      id,
    });
    const itemListSearchOption = yield select((state) => state.clinic.itemListSearchOption);

    yield put(clinicAction.updateClinicProductDisplaySuccess(response.data));
    if (response.status_code === 1) {
      const result = yield Swal.fire({
        title: "변경되었습니다.",
        icon: "success",
        draggable: true,
      });

      if (result.isConfirmed) {
        yield put(
          clinicAction.getClinicProductList({
            userToken,
            store_current_id,
            page: itemListSearchOption.page,
            size: itemListSearchOption.size,
            sort: itemListSearchOption.sortOption,
          })
        );
      }
    }
  } catch (error) {
    Swal.fire({
      title: "오류가 발생하였습니다.",
      icon: "error",
    });
    yield put(clinicAction.updateClinicProductDisplayError(error));
  }
}

function* getReservationSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.getReservation, { ...action.payload, store_current_id });
    yield put(clinicAction.getReservationSuccess(response));
  } catch (error) {
    console.error("Saga Error:", error); // 에러 로그 확인
    yield put(clinicAction.getReservationError(error.message));
  }
}

function* refundReservationSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.refundReservation, {
      ...action.payload,
      store_current_id,
    });

    if (response.status_code === 1) {
      const result = yield Swal.fire({
        title: "환불이 완료되었습니다.",
        icon: "success",
        draggable: true,
      });
      const { searchParams } = action.payload;

      yield put(
        clinicAction.getReservationList({
          userToken: action.payload.userToken,
          store_current_id,
          ...searchParams,
        })
      );

      yield put(
        clinicAction.getReservationList({
          userToken: action.payload.userToken,
          store_current_id,
          ...searchParams,
        })
      );

      yield put(clinicAction.refundReservationSuccess(response));
      if (result.isConfirmed) {
        yield put(clinicAction.setRefundModalOpen(false));
        yield put(clinicAction.setRefundModalData(null));
      }
    } else {
      yield Swal.fire({
        title: "환불 실패",
        icon: "error",
        text: "환불요청에 실패하였습니다.",
      });
    }
  } catch (error) {
    yield put(clinicAction.refundReservationError(error.message));
    yield Swal.fire({
      title: "환불 실패",
      icon: "error",
      text: "환불요청에 실패하였습니다.",
    });
  }
}

function* withdrawCancelReservationSaga(action) {
  try {
    const { userToken, book_mst_id } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const reservationSearchOption = yield select((state) => state.clinic.reservationSearchOption);

    let searchParams = {
      dateType: reservationSearchOption.searchType,
      page: reservationSearchOption.page,
      size: reservationSearchOption.size,
      sort: reservationSearchOption.sort,
      searchType: reservationSearchOption.searchOption,
      searchValue: reservationSearchOption.searchValue,
    };

    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield call(clinicApi.withdrawCancelReservation, {
      userToken,
      book_mst_id,
      store_current_id,
    });

    if (response.status_code === 1) {
      const result = yield Swal.fire({
        title: "환불 요청 철회가 완료되었습니다.",
        icon: "success",
        draggable: true,
      });
      if (result.isConfirmed) {
        yield put(clinicAction.withdrawCancelReservationSuccess(response));
        const response2 = yield call(clinicApi.getReservationList, {
          userToken,
          ...searchParams,
          store_current_id,
        });
        if (response2.status_code === 1) {
          yield put(clinicAction.getReservationListSuccess(response2.data));
        }
      }
    }
  } catch (error) {
    yield put(clinicAction.withdrawCancelReservationError(error.message));
  }
}

function* cancelReservationSaga(action) {
  try {
    const response = yield call(clinicApi.cancelReservation, action.payload);
    if (response.status_code === 1) {
      const result = yield Swal.fire({
        title: "예약이 취소되었습니다.",
        icon: "success",
        draggable: true,
      });

      if (result.isConfirmed) {
        yield put(clinicAction.cancelReservationSuccess(response));
      }
    }
  } catch (error) {
    yield put(clinicAction.cancelReservationError(error.message));
  }
}

function* getReservationListSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.getReservationList, {
      ...action.payload,
      store_current_id,
    });
    yield put(clinicAction.getReservationListSuccess(response.data));
  } catch (error) {
    yield put(clinicAction.getReservationListError(error.message));
  }
}

const deleteReservationSaga = createAsyncSaga(
  clinicApi.deleteReservation,
  clinicAction.deleteReservationSuccess,
  clinicAction.deleteReservationError
);

// 판매시간 설정 관련Saga

function* getSalesTimeListSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const itemListSearchOption = yield select((state) => state.clinic.itemListSearchOption);
    const itemTimeLineSelectedProduct = yield select(
      (state) => state.clinic.itemTimeLineSelectedProduct
    );

    let params = {
      userToken: action.payload.userToken,
      storeId: action.payload.storeId,
      store_current_id,
      today: itemListSearchOption.searchDate ?? moment(new Date()).format("YYYYMM01"),
    };
    if (itemTimeLineSelectedProduct !== "all") {
      params = {
        ...params,
        clinicId: itemTimeLineSelectedProduct.id,
        searchType: "CALENDAR_ITEM",
        searchValue: itemTimeLineSelectedProduct.id,
      };
    }

    const response = yield call(clinicApi.getSalesTimeList, params);
    if (response.status_code === 1) {
      yield put(clinicAction.getSalesTimeListSuccess(response.data));
    } else {
      Swal.fire({
        title: "판매시간 조회에 실패하였습니다.",
        icon: "error",
      });
    }
  } catch (error) {
    yield put(clinicAction.getSalesTimeListError(error.message));
  }
}

function* getSalesTimeSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.getSalesTime, { ...action.payload, store_current_id });
    if (response.status_code === 1) {
      yield put(clinicAction.setSalesTimeUpdateModalOpen(true));
      yield put(clinicAction.setSalesTimeUpdateModalData(response.data));
      yield put(clinicAction.getSalesTimeSuccess(response.data));
    } else {
      Swal.fire({
        title: "판매시간 조회에 실패하였습니다.",
        icon: "error",
      });
    }
  } catch (error) {
    yield put(clinicAction.getSalesTimeError(error.message));
  }
}

function* createSalesTimeSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.createSalesTime, { ...action.payload, store_current_id });
    const rout = action.payload.router;
    if (response.status_code === 1) {
      Swal.fire({
        title: "판매시간 등록이 완료되었습니다.",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          rout.replace("/clinic/itemtimeline");
        }
      });
      yield put(clinicAction.createSalesTimeSuccess(response));
    } else {
      Swal.fire({
        title: "판매시간 등록에 실패하였습니다.",
        icon: "error",
      });
    }
  } catch (error) {
    yield put(clinicAction.createSalesTimeError(error.message));
  }
}

function* updateSalesTimeSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.updateSalesTime, { ...action.payload, store_current_id });
    if (response.status_code === 1) {
      yield put(clinicAction.updateSalesTimeSuccess(response));
      Swal.fire({
        title: "수정이 완료되었습니다.",
        icon: "success",
      });
      yield put(
        clinicAction.getSalesTimeList({
          userToken: action.payload.userToken,
          storeId: action.payload.storeId,
        })
      );
      yield put(clinicAction.setSalesTimeUpdateModalOpen(false));
      yield put(clinicAction.setSalesTimeUpdateModalData(null));
    }
  } catch (error) {
    yield put(clinicAction.updateSalesTimeError(error.message));
  }
}

function* deleteSalesTimeSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.deleteSalesTime, { ...action.payload, store_current_id });
    if (response.status_code === 1) {
      yield put(clinicAction.deleteSalesTimeSuccess(response));
      Swal.fire({
        title: "삭제가 완료되었습니다.",
        icon: "success",
      });
      yield put(
        clinicAction.getSalesTimeList({
          userToken: action.payload.userToken,
          storeId: action.payload.storeId,
        })
      );
      yield put(clinicAction.setSalesTimeUpdateModalOpen(false));
      yield put(clinicAction.setSalesTimeUpdateModalData(null));
    }
  } catch (error) {
    yield put(clinicAction.deleteSalesTimeError(error.message));
  }
}

function* deleteSalesTimeBulkSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const response = yield call(clinicApi.deleteSalesTimeBulk, {
      ...action.payload,
      store_current_id,
    });
    if (response.status_code === 1) {
      yield put(clinicAction.deleteSalesTimeBulkSuccess(response));
      Swal.fire({
        title: "삭제가 완료되었습니다.",
        icon: "success",
      });
      yield put(
        clinicAction.getSalesTimeList({
          userToken: action.payload.userToken,
          storeId: action.payload.storeId,
        })
      );
    }
  } catch (error) {
    yield put(clinicAction.deleteSalesTimeBulkError(error.message));
  }
}

function* getClinicNotificationListSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const tokenInfo = jwtDecode(userToken);
    const clinicNotificationSearchOption = yield select(
      (state) => state.clinic.clinicNotificationSearchOption
    );
    let params = {
      ...action.payload,
      storeId: tokenInfo.storeInfo.id,
      store_current_id,
      page: clinicNotificationSearchOption.page,
      size: clinicNotificationSearchOption.size,
    };
    const response = yield call(clinicApi.getNotificationList, params);
    yield put(clinicAction.getClinicNotificationListSuccess(response.data));
    yield put(clinicAction.setIsNewNoti(false));
  } catch (error) {
    yield put(clinicAction.getClinicNotificationListError(error.message));
  }
}

function* getClinicNotificationListShortSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const tokenInfo = jwtDecode(userToken);
    let params = {
      userToken: userToken,
      store_current_id,
      storeId: tokenInfo.storeInfo.id,
      page: 0,
      size: 10,
    };
    const response = yield call(clinicApi.getNotificationList, params);
    yield put(clinicAction.getClinicNotificationListShortSuccess(response.data));
    yield put(clinicAction.setIsNewNoti(false));
  } catch (error) {
    yield put(clinicAction.getClinicNotificationListShortError(error.message));
  }
}

function* changeReservationAdminMemoSaga(action) {
  try {
    const { userToken, book_admin_memo, book_mst_id } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;
    const tokenInfo = jwtDecode(userToken);

    const response = yield call(clinicApi.changeReservationAdminMemoApi, {
      book_admin_memo,
      book_mst_id,
      userToken,
      store_current_id,
    });

    if (response.status_code === 1) {
      yield put(clinicAction.changeReservationAdminMemoSuccess(response));
      Swal.fire({
        title: "관리자 메모가 변경되었습니다.",
        icon: "success",
      });
    } else {
      Swal.fire({
        title: "관리자 메모 변경에 실패하였습니다.",
        icon: "error",
      });
    }
  } catch (err) {
    console.log(err);
    yield put(clinicAction.changeReservationAdminMemoError(err.message));
  }
}

// watcher 코드
function* watchWithdrawCancelReservationSaga() {
  yield takeLatest(clinicAction.withdrawCancelReservation, withdrawCancelReservationSaga);
}

function* watchChangeReservationAdminMemoSaga() {
  yield takeLatest(clinicAction.changeReservationAdminMemo, changeReservationAdminMemoSaga);
}

function* watchGetClinicNotificationListShortSaga() {
  yield takeLatest(clinicAction.getClinicNotificationListShort, getClinicNotificationListShortSaga);
}

function* watchGetClinicNotificationList() {
  yield takeLatest(clinicAction.getClinicNotificationList, getClinicNotificationListSaga);
}

function* watchDeleteSalesTimeBulk() {
  yield takeLatest(clinicAction.deleteSalesTimeBulk, deleteSalesTimeBulkSaga);
}

function* watchGetSalesTimeList() {
  yield takeLatest(clinicAction.getSalesTimeList, getSalesTimeListSaga);
}
function* watchGetSalesTime() {
  yield takeLatest(clinicAction.getSalesTime, getSalesTimeSaga);
}
function* watchCreateSalesTime() {
  yield takeLatest(clinicAction.createSalesTime, createSalesTimeSaga);
}
function* watchUpdateSalesTime() {
  yield takeLatest(clinicAction.updateSalesTime, updateSalesTimeSaga);
}
function* watchDeleteSalesTime() {
  yield takeLatest(clinicAction.deleteSalesTime, deleteSalesTimeSaga);
}

function* watchCancelReservation() {
  yield takeLatest(clinicAction.cancelReservation, cancelReservationSaga);
}

function* watchRefundReservation() {
  yield takeLatest(clinicAction.refundReservation, refundReservationSaga);
}

function* watchGetReservation() {
  yield takeLatest(clinicAction.getReservation, getReservationSaga);
}
function* watchDeleteReservation() {
  yield takeLatest(clinicAction.deleteReservation, deleteReservationSaga);
}

function* watchGetReservationList() {
  yield takeLatest(clinicAction.getReservationList, getReservationListSaga);
}

function* watchUpdateClinicProductDisplay() {
  yield takeLatest(clinicAction.updateClinicProductDisplay, updateClinicProductDisplaySaga);
}

function* watchUpdateClinicProductOrder() {
  yield takeLatest(clinicAction.updateClinicProductOrder, updateClinicProductOrderSaga);
}

function* watchGetClinicProduct() {
  yield takeLatest(clinicAction.getClinicProduct, getClinicProductSaga);
}

function* watchGetClinicProductList() {
  yield takeLatest(clinicAction.getClinicProductList, getClinicProductListSaga);
}

function* watchUpdateClinicProduct() {
  yield takeLatest(clinicAction.updateClinicProduct, updateClinicProductSaga);
}

function* watchCopyClinicProduct() {
  yield takeLatest(clinicAction.copyClinicProduct, copyClinicProductSaga);
}

function* watchCreateClinicProduct() {
  yield takeLatest(clinicAction.createClinicProduct, createClinicProductSaga);
}

function* watchDeleteClinicProduct() {
  yield takeLatest(clinicAction.deleteClinicProduct, deleteClinicProductSaga);
}

export function* clinicSaga() {
  yield all([
    fork(watchGetClinicProduct),
    fork(watchGetClinicProductList),
    fork(watchUpdateClinicProduct),
    fork(watchCopyClinicProduct),
    fork(watchCreateClinicProduct),
    fork(watchDeleteClinicProduct),
    fork(watchUpdateClinicProductOrder),
    fork(watchUpdateClinicProductDisplay),
    fork(watchGetReservationList),
    fork(watchGetReservation),
    fork(watchDeleteReservation),
    fork(watchRefundReservation),
    fork(watchCancelReservation),
    fork(watchGetSalesTimeList),
    fork(watchGetSalesTime),
    fork(watchCreateSalesTime),
    fork(watchUpdateSalesTime),
    fork(watchDeleteSalesTime),
    fork(watchDeleteSalesTimeBulk),
    fork(watchGetClinicNotificationList),
    fork(watchGetClinicNotificationListShortSaga),
    fork(watchChangeReservationAdminMemoSaga),
    fork(watchWithdrawCancelReservationSaga),
  ]);
}
