import axios from "axios";
import { HeaderPoint } from "@store/api.util";

export const getUserPointAmount = async (customerId) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_STORE_DEV_API_HOST}/admin/point/customer/${customerId}`, {
      headers: {
        ...HeaderPoint,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getUserPointEvent = async (customerId) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_STORE_DEV_API_HOST}/admin/point/event/${customerId}`, {
      headers: {
        ...HeaderPoint,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getUserPointList = async ({ data }) => {
  var params = new URLSearchParams(data).toString();
  var url = `${process.env.NEXT_PUBLIC_STORE_DEV_API_HOST}/admin/point/list?` + params;

  return axios
    .get(url, {
      headers: {
        ...HeaderPoint,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getPointTotal = async () => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_STORE_DEV_API_HOST}/admin/point/total`, {
      headers: {
        ...HeaderPoint,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 새롭게 바뀐 리워드 리스트 불러오기
export const getRewardList = async () => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_STORE_DEV_API_HOST}/admin/reward/list`, {
      headers: {
        ...HeaderPoint,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const pushReward = async (data) => {
  return axios
    .post(`${process.env.NEXT_PUBLIC_STORE_DEV_API_HOST}/admin/reward`, data, {
      headers: {
        ...HeaderPoint,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};
