import { takeLatest, put, all, fork, select, delay } from "redux-saga/effects";
import * as frequencytrackingApi from "./frequencytrackingApi";
import Swal from "sweetalert2";
import { frequencytrackingAction } from "./frequencytrackingSlice";

function* getFrequencyTrackingListSaga(action) {
  try {
    const { userToken, page, size, searchParams } = action.payload;
    const response = yield frequencytrackingApi.getFrequencyTrackingListApi({
      userToken,
      page,
      size,
      searchParams,
    });
    if (response.status_code === 1) {
      yield put(frequencytrackingAction.getFrequencyTrackingListSuccess(response.data));
    } else {
      yield put(frequencytrackingAction.getFrequencyTrackingListError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getFrequencyTrackingDetailListSaga(action) {
  try {
    const { userToken, searchParams } = action.payload;
    const response = yield frequencytrackingApi.getFrequencyTrackingDetailListApi({
      userToken,
      searchParams,
    });
    if (response.status_code === 1) {
      yield put(frequencytrackingAction.getFrequencyDetailListSuccess(response.data));
      yield put(frequencytrackingAction.setFrequencyDetailListPopupOpen(true));
    } else {
      yield put(frequencytrackingAction.getFrequencyDetailListError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* exportFrequencyTrackingListSaga(action) {
  try {
    const { userToken, type, searchParams } = action.payload;
    const response = yield frequencytrackingApi.exportExcelFrequencyTrakingApi({
      userToken,
      type,
      searchParams,
    });
    if (response) {
      yield put(frequencytrackingAction.exportFrequencyTrackingListSuccess());
    }
  } catch (err) {
    console.log(err);
  }
}

function* watchExportFrequencyTrackingListSaga() {
  yield takeLatest(
    frequencytrackingAction.exportFrequencyTrackingList,
    exportFrequencyTrackingListSaga
  );
}

function* watchGetFrequencyTrackingSaga() {
  yield takeLatest(frequencytrackingAction.getFrequencyTrackingList, getFrequencyTrackingListSaga);
}

function* watchGetFrequencyDetailListSaga() {
  yield takeLatest(
    frequencytrackingAction.getFrequencyDetailList,
    getFrequencyTrackingDetailListSaga
  );
}

export function* frequencyTrackingSaga() {
  yield all([
    fork(watchGetFrequencyTrackingSaga),
    fork(watchExportFrequencyTrackingListSaga),
    fork(watchGetFrequencyDetailListSaga),
  ]);
}
