import axios from "axios";

// sms template 리스트 조회
export const getSmsTemplateListApi = async ({
  userToken,
  store_current_id,
  storeId,
  page,
  size,
  searchType,
  searchValue,
}) => {
  console.log(store_current_id);
  let url = "";
  if (!searchValue || searchValue.trim() === "") {
    url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/${store_current_id}/templates?page=${page}&size=${size}`;
  } else if (searchValue.trim() !== "" && searchType !== "SMS_TEMPLATE_TTL") {
    url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/${store_current_id}/templates?page=${page}&size=${size}&searchType=${searchType}&searchValue=${searchValue}`;
  } else {
    url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/${store_current_id}/templates?page=${page}&size=${size}`;
  }
  return axios
    .get(url, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
        "Store-Id": store_current_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// sms template 단건 조회
export const getSmsTemplateApi = async ({ userToken, store_current_id, templateId }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/templates/${templateId}`, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
        "Store-Id": store_current_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// sms template 저장 및 수정
export const saveSmsTemplateApi = async ({ userToken, store_current_id, data }) => {
  return axios
    .post(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/templates`, data, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
        "Store-Id": store_current_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// sms template 삭제
export const deleteSmsTemplateApi = async ({ userToken, store_current_id, templateId }) => {
  return axios
    .delete(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/templates/${templateId}`, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
        "Store-Id": store_current_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// 판매주문목록에서 sms 선택 전송
export const sendSmsApi = async ({ userToken, store_current_id, data }) => {
  return axios
    .post(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/send`, data, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
        "Store-Id": store_current_id,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// sms history 리스트 조회
export const getSmsHistoryListApi = async ({
  userToken,
  store_current_id,
  storeId,
  page,
  size,
  searchType,
}) => {
  console.log(searchType);
  return axios
    .get(
      `${
        process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST
      }/admin/sms/send/${store_current_id}/history?page=${page}&size=${size}${
        searchType ? `&searchType=SMS_HISTORY_CONDITIONS&searchValue=${searchType}` : ""
      }`,
      {
        headers: {
          "Authorization-Play": `Bearer ${userToken}`,
          Accept: "application/json",
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// sms 재전송
export const resendSmsApi = async ({ userToken, store_current_id, id }) => {
  // v1/admin/sms/send/{smsHistoryId}/resend
  return axios
    .post(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/sms/${id}/resend`,
      {},
      {
        headers: {
          "Authorization-Play": `Bearer ${userToken}`,
          Accept: "application/json",
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
