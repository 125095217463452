import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";
import dayjs from "dayjs";

const name = "reward";

const initialState = {
  searchType: "ALL",
  searchValue: "",
  page: 0,
  size: 10,
  createGroupData: {
    title: "",
    keyword: "", // id 역할임 ㅋ_ㅋ
    use_yn: "Y",
    start_date: dayjs().format("YYYY-MM-DD HH:mm"),
    end_date: dayjs().add(7, "day").format("YYYY-MM-DD HH:mm"), // end_date는 약 일주일 후로 자동 설정
    display_yn: "Y",
  },
  updateGroupData: {
    title: "",
    keyword: "", // id 역할임 ㅋ_ㅋ
    use_yn: "Y",
    start_date: dayjs().format("YYYY-MM-DD HH:mm"),
    end_date: dayjs().add(7, "day").format("YYYY-MM-DD HH:mm"), // end_date는 약 일주일 후로 자동 설정
    display_yn: "Y",
  },
  createRewardData: {
    keyword: "",
    title: "",
    description: "",
    limit_cnt: 0,
    use_flag: "Y",
    start_date: dayjs().format("YYYY-MM-DD HH:mm"),
    end_date: dayjs().add(7, "day").format("YYYY-MM-DD HH:mm"), // end_date는 약 일주일 후로 자동 설정
    display_date: dayjs().format("YYYY-MM-DDTHH:mm"),
    reward_type: "C",
    expired_day: 0,
    coupon_id: "",
    customer_coupon_text: "",
    point: 0,
    long_image_url: "",
    short_image_url: "",
    reward_cond: {
      issue_type: "MANUAL", // MANUAL, AUTO
      auto_type: "", // VISIT_STAT, PRD_PRCH, PRODUCT
      auto_issue_time: "",
      auto_url: "",
      auto_product_id: "",
      auto_start_date: "",
      auto_end_date: "",
      priority: 0,
    },
    reward_grp_id: null,
  },
  updateRewardData: {
    keyword: "",
    title: "",
    description: "",
    limit_cnt: 0,
    use_flag: "Y",
    start_date: dayjs().format("YYYY-MM-DD HH:mm"),
    end_date: dayjs().add(7, "day").format("YYYY-MM-DD HH:mm"), // end_date는 약 일주일 후로 자동 설정
    display_date: dayjs().format("YYYY-MM-DD HH:mm"),
    reward_type: "C",
    expired_day: 0,
    coupon_id: "",
    customer_coupon_text: "",
    point: 0,
    long_image_url: "",
    short_image_url: "",
    reward_cond: {
      issue_type: "MANUAL", // MANUAL, AUTO
      auto_type: "", // VISIT_STAT, PRD_PRCH, PRODUCT
      auto_issue_time: "",
      auto_url: "",
      auto_product_id: "",
      auto_start_date: "",
      auto_end_date: "",
      priority: 0,
    },
    reward_grp_id: null,
  },
  createRewardModalOpen: false,
  updateRewardModalOpen: false,
  createRewardGroupModalOpen: false,
  manageRewardGroupModalOpen: false,
  updateRewardGroupModalOpen: false,

  getRewardGroupList: reducerUtils.initial(),
  getRewardList: reducerUtils.initial(),
  createRewardGroup: reducerUtils.initial(),
  updateRewardGroup: reducerUtils.initial(),
  deleteRewardGroup: reducerUtils.initial(),
  createReward: reducerUtils.initial(),
  updateReward: reducerUtils.initial(),
  deleteReward: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getRewardGroupList", "getRewardGroupList"),
    ...createSagaReducer("getRewardList", "getRewardList"),
    ...createSagaReducer("createRewardGroup", "createRewardGroup"),
    ...createSagaReducer("updateRewardGroup", "updateRewardGroup"),
    ...createSagaReducer("deleteGroup", "deleteGroup"),
    ...createSagaReducer("createReward", "createReward"),
    ...createSagaReducer("updateReward", "updateReward"),
    ...createSagaReducer("deleteReward", "deleteReward"),

    setInitCreateGroupData(state, action) {
      state.createGroupData = initialState.createGroupData;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSize(state, action) {
      state.size = action.payload;
    },
    setRewardState(state, action) {
      const { target, data } = action.payload;
      state[target] = data;
    },
    setCreateRewardData(state, action) {
      const { key, value } = action.payload;
      console.log(key, value);
      state.createRewardData = {
        ...state.createRewardData,
        [key]: value,
      };
    },
    setCreateRewardModalOpen(state, action) {
      state.createRewardModalOpen = action.payload;
    },
    setCreateRewardGroupModalOpen(state, action) {
      state.createRewardGroupModalOpen = action.payload;
    },
    setManageRewardGroupModalOpen(state, action) {
      state.manageRewardGroupModalOpen = action.payload;
    },
    setUpdateRewardGroupModalOpen(state, action) {
      state.updateRewardGroupModalOpen = action.payload;
    },
    setUpdateRewardModalOpen(state, action) {
      state.updateRewardModalOpen = action.payload;
    },
    setCreateGroupData(state, action) {
      const { key, value } = action.payload;
      state.createGroupData = {
        ...state.createGroupData,
        [key]: value,
      };
    },
    setUpdateGroupData(state, action) {
      const { key, value } = action.payload;
      state.updateGroupData = {
        ...state.updateGroupData,
        [key]: value,
      };
    },
    setUpdateGroupDataAll(state, action) {
      state.updateGroupData = action.payload;
    },
    setUpdateRewardData(state, action) {
      const { key, value } = action.payload;
      state.updateRewardData = {
        ...state.updateRewardData,
        [key]: value,
      };
    },
    setUpdateRewardDataAll(state, action) {
      state.updateRewardData = action.payload;
    },
    setInitUpdateRewardData(state, action) {
      state.updateRewardData = initialState.updateRewardData;
    },
    reset: (state, action) => {},
  },
});

export const REWARD = slice.name;
export const rewardReducer = slice.reducer;
export const rewardAction = slice.actions;
