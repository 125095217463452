import axios from "axios";
import { HeaderBasic, HeaderFormData } from "@store/api.util";

export const getClayfulItemId = async (itemId) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/products/${itemId}`, {
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const insertClayfulImage = async (data) => {
  const body = {
    model: null,
    application: null,
    file: data,
  };

  return axios
    .post(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/images`, body, {
      headers: {
        ...HeaderFormData,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      return Error;
    });
};

export const getClayfulUser = async (customerId) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/customers/${customerId}`, {
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getClayfulUserCoupons = async (customerId) => {
  console.log("customerId", customerId);
  return axios
    .get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/customers/${customerId}/coupons`, {
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const pushClayfulUserCoupons = async (customerId, coupon) => {
  return axios
    .post(
      `${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/customers/${customerId}/coupons`,
      {
        coupon: coupon,
      },
      {
        headers: {
          ...HeaderBasic,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const deleteClayfulUserCoupons = async ({ customerId, slug }) => {
  console.log(customerId, slug);
  return axios
    .delete(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/customers/${customerId}/coupons/${slug}`, {
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getClayfulBrands = async () => {
  const count = await axios
    .get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/brands/count`, {
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });

  const limit = 120;
  const pageNumber = Math.ceil(count.count.raw / limit);

  async function* asyncGenerator(max) {
    let i = 1;
    while (i <= max) {
      yield i++;
    }
  }

  let result = [];

  for await (const page of asyncGenerator(pageNumber)) {
    const brands = await axios
      .get(
        `${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/brands?limit=${limit}&page=${page}&sort=createdAt`,
        {
          headers: {
            ...HeaderBasic,
          },
        }
      )
      .then((res) => res.data)
      .catch((Error) => {
        console.log(Error);
      });

    result.push(...brands);
  }

  return result.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
};

export const getClayfulProducts = (data = null) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/products`, {
      params: data,
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data);
};

export const getClayfulProductsCount = (data = null) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/products/count`, {
      params: data,
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data);
};

export const putClayfulVariants = ({ productId, variantId, payload }) => {
  return axios
    .put(
      `${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/products/${productId}/variants/${variantId}`,
      payload,
      {
        headers: {
          ...HeaderBasic,
        },
      }
    )
    .then((res) => res.data);
};

export const putClayfulProducts = ({ productId, payload }) => {
  return axios
    .put(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/products/${productId}`, payload, {
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data);
};

export const getClayfulAllCatalogs = () => {
  const params = `?fields=title,meta,_id,createdAt`;
  return axios
    .get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/catalogs${params}`, {
      headers: {
        ...HeaderBasic,
      },
    })
    .then((res) => res.data);
};
