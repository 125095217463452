import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";
import dayjs from "dayjs";

const name = "recenttracking";

const initialState = {
  searchType: "customerId", // || "customer"
  searchValue: "",
  strDate: dayjs().subtract(5, "year").format("YYYYMMDD"), // 5년 전
  endDate: dayjs().add(5, "year").format("YYYYMMDD"), // 5년 후

  page: 0,
  size: 100,
  popupPage: 0,
  popupSize: 1,
  recentDetailListPopup: {
    open: false,
    data: null,
  },

  sendCartSms: reducerUtils.initial(),
  getRecentTrackingList: reducerUtils.initial(),
  getRecentDetailList: reducerUtils.initial(),
  exportRecentTrackingList: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("sendCartSms", "sendCartSms"),
    ...createSagaReducer("getRecentTrackingList", "getRecentTrackingList"),
    ...createSagaReducer("exportRecentTrackingList", "exportRecentTrackingList"),
    ...createSagaReducer("getRecentDetailList", "getRecentDetailList"),
    setRecentDetailListPopup(state, action) {
      state.recentDetailListPopup = action.payload;
    },
    setRecentDetailListPopupOpen(state, action) {
      state.recentDetailListPopup.open = action.payload;
    },
    setRecentDetailListPopupData(state, action) {
      state.recentDetailListPopup.data = action.payload;
    },
    setPopupPage(state, action) {
      state.popupPage = action.payload;
    },
    setPopupSize(state, action) {
      state.popupSize = action.payload;
    },
    setStrDate(state, action) {
      state.strDate = action.payload;
    },
    setEndDate(state, action) {
      state.endDate = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSize(state, action) {
      state.size = action.payload;
    },
    setSearchType(state, action) {
      state.searchType = action.payload;
    },
    setSearchOption(state, action) {
      state.searchOption = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    initRecentSlice(state) {
      state.searchType = "customerId";
      state.searchValue = "";
      state.strDate = dayjs().subtract(5, "year").format("YYMMDD");
      state.endDate = dayjs().add(5, "year").format("YYMMDD");
      state.page = 0;
      state.size = 100;
      state.popupPage = 0;
      state.popupSize = 1;
      state.recentDetailListPopup = {
        open: false,
        data: null,
      };
      state.sendCartSms = reducerUtils.initial();
      state.getRecentTrackingList = reducerUtils.initial();
      state.getRecentDetailList = reducerUtils.initial();
      state.exportRecentTrackingList = reducerUtils.initial;
    },
  },
  reset: (state) => {},
});

export const RECENTTRACKING = slice.name;
export const recenttrackingReducer = slice.reducer;
export const recenttrackingAction = slice.actions;
