import Head from "next/head";
import { CacheProvider } from "@emotion/react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { createEmotionCache } from "@utils/create-emotion-cache";
import { theme } from "@theme/index";
import wrapper from "@store/configureStore";
import withReduxSaga from "next-redux-saga";
import { SessionProvider } from "next-auth/react";
import AuthWrapper from "@components/AuthWrapper";
import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import ErrorBoundary from "../components/ErrorBoundary";
import { useRouter } from "next/router";
import SnackbarComponent from "@components/snackbar/Snackbar";
import { useDispatch } from "react-redux";
import "../../styles/global.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "react-color-palette/css";
import "react-tagsinput/react-tagsinput.css";

const clientSideEmotionCache = createEmotionCache();

const App = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();
  const dispatch = useDispatch();
  const bInitChallengeSession =
    router.pathname === "/challenge" || router.pathname === "/challenge/update/[id]";
  const bClinicResultSession =
    router.pathname === "/csm/clinic/result" || router.pathname === "/csm/clinic/result/report";

  if (typeof window !== "undefined") {
    if (!bInitChallengeSession) {
      sessionStorage.removeItem("challengeCurPage");
    }
    if (!bClinicResultSession) {
      sessionStorage.removeItem("csClinicId");
      sessionStorage.removeItem("csSeq");
    }
  }

  // interceptor response
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      Sentry.captureEvent(error);
      return Promise.reject(error);
    }
  );

  return (
    <SessionProvider session={props.session}>
      <AuthWrapper>
        <CacheProvider value={emotionCache}>
          <Head>
            <title>놀로</title>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <ErrorBoundary>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>
            </ThemeProvider>
          </LocalizationProvider>
          <SnackbarComponent />
        </CacheProvider>
      </AuthWrapper>
    </SessionProvider>
  );
};

export default wrapper.withRedux(withReduxSaga(App));
