import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";

const name = "carttracking";

const initialState = {
  searchType: "customerId", // || "customer"
  searchValue: "",
  orderStatus: "SAVE",

  page: 0,
  size: 100,

  cartCustomerListPopup: {
    open: false,
    data: null,
  },
  cartProductListPopup: {
    open: false,
    data: null,
  },
  popupPage: 0,
  popupSize: 100,

  cartSmsModalOpen: false,
  cartSmsModalData: {
    sms_template_id: null,
    sms_ttl: "",
    sms_cnts: "",
    receiver_no_list: [],
    sms_send_type: "sms",
  },

  getCartTrackingList: reducerUtils.initial(),
  getCartTrackingDetail: reducerUtils.initial(),
  exportCartTrackingList: reducerUtils.initial(),
  exportCartTrackinDetailList: reducerUtils.initial(),
  sendCartSms: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getCartTrackingList", "getCartTrackingList"),
    ...createSagaReducer("getCartTrackingDetail", "getCartTrackingDetail"),
    ...createSagaReducer("exportCartTrackingList", "exportCartTrackingList"),
    ...createSagaReducer("exportCartTrackinDetailList", "exportCartTrackinDetailList"),
    ...createSagaReducer("sendCartSms", "sendCartSms"),
    setCartSmsModalOpen(state, action) {
      state.cartSmsModalOpen = action.payload;
    },
    setCartSmsModalData(state, action) {
      state.cartSmsModalData = action.payload;
    },
    setPopupPage(state, action) {
      state.popupPage = action.payload;
    },
    setPopupSize(state, action) {
      state.popupSize = action.payload;
    },
    setOrderStatus(state, action) {
      state.orderStatus = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSize(state, action) {
      state.size = action.payload;
    },
    setCartCustomerListPopup(state, action) {
      state.cartCustomerListPopup = action.payload;
    },
    setCartProductListPopup(state, action) {
      state.cartProductListPopup = action.payload;
    },
    setSearchType(state, action) {
      state.searchType = action.payload;
    },
    setSearchOption(state, action) {
      state.searchOption = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    initSearch(state) {
      state.searchType = "product";
      state.searchOption = "name";
      state.searchValue = "";
      state.sort = "recent";
    },
  },
  reset: (state) => {},
});

export const CARTTRACKING = slice.name;
export const carttrackingReducer = slice.reducer;
export const carttrackingAction = slice.actions;
