import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";
import { set } from "lodash";
import { cancelReservation, copyClinicProduct, updateClinicProduct } from "./clinicApi";

const name = "clinic";

const initialState = {
  isNewNoti: false,
  admin_current_store_id: 2,
  createProduct: {
    item_display_yn: false,
    item_name: "",
    item_store_category: "",
    item_price: 0,
    item_group: 0,

    discount_builder: {
      discount_name: "",
      discount_type: "DEFAULT", // default || price || percent
      discount_value: 0,
      discount_apply_str: "",
      discount_apply_end: "",
      use_yn: false,
    },
    item_order_limit_list: [
      {
        id: null,
        limit_type: "QUANTITY",
        limit_value: 0,
      },
    ],
    item_short_desc: "",
    item_prmr_clr: "",
    item_type: "TIME",
    item_item_display_yn: false,
    comm_file_grp_builder: null,
    item_dtl_builder_list: [
      {
        item_dtl_type: "DETAIL",
        item_dtl_name: "탭1",
        item_dtl_content_type: "HTML",
        item_dtl_content: "내용",
      },
    ],
    item_input_info_builder_list: [],
    item_template_builder_list: [
      {
        template_name: "",
        template_price: 0,
        quantity_label: "개",
        sales_quantity: 0,
        item_template_opt: [],
      },
    ],
    item_refund_policy_builder_list: [],
  },

  getClinicProductList: reducerUtils.initial(),
  getClinicProduct: reducerUtils.initial(),
  updateClinicProduct: reducerUtils.initial(),
  copyClinicProduct: reducerUtils.initial(),
  createClinicProduct: reducerUtils.initial(),
  deleteClinicProduct: reducerUtils.initial(),
  updateClinicProductOrder: reducerUtils.initial(),
  updateClinicProductDisplay: reducerUtils.initial(),

  getClinicProductPageable: {},
  itemListSearchOption: {
    filterOption: "ITEM_ALL",
    sortOption: "itemSort,asc",
    searchText: "",
    searchDate: null,
    page: 0,
    size: 10,
  }, // all || name || keyword || memo
  searchOptions: {
    productId: "",
    filterOption: "productId", // productId || title || date
    filterValue: "", // string || { startDate, endDate }
    startDate: "",
    endDate: "",
    inlcudePast: false,
  },

  // 판매시간 설정 관련
  itemTimeLineSelectedProduct: "all",
  itemTimeLineSelectedProductInfo: {},
  itemTimeLineSelectedTime: [
    { startHour: "00", startMinute: "00", endHour: "01", endMinute: "00" },
  ],
  itemTimeLineSelectedPeriod: {
    startDate: "",
    endDate: "",
  },
  itemTimeLineSelectedDate: [],
  itemTimeLineSelectedTemplate: null,

  // 예약정보 관련
  getReservation: reducerUtils.initial(),
  getReservationList: reducerUtils.initial(),
  createReservation: reducerUtils.initial(),
  refundReservation: reducerUtils.initial(),
  cancelReservation: reducerUtils.initial(),
  withdrawCancelReservation: reducerUtils.initial(),
  deleteReservation: reducerUtils.initial(),
  changeReservationAdminMemo: reducerUtils.initial(),

  reservationSearchOption: {
    page: 0,
    size: 10,
    searchType: "bookUseDate", // bookVisitDate
    searchStatus: "all",
    searchOption: "BOOK_SEARCH_ALL",
    searchValue: "",
    period: {
      periodRange: "all",
      startDate: "",
      endDate: "",
    },
    visitTime: {
      startHour: "0",
      startMin: "0",
      endHour: "0",
      endMin: "0",
    },
    itemId: "all",
    sort: "id,desc",
  },
  reservationSelected: [],
  registerProductTimeLine: reducerUtils.initial(),

  orderModalOpen: false,
  orderModalData: {},
  createReservationModalOpen: false,
  createReservationModalData: {
    selectedClinicProduct: {},
  },

  // 판매시간 등록 관련
  getSalesTimeList: reducerUtils.initial(),
  getSalesTime: reducerUtils.initial(), // 판매시간설정 단건 조회
  createSalesTime: reducerUtils.initial(),
  updateSalesTime: reducerUtils.initial(),
  deleteSalesTime: reducerUtils.initial(),
  deleteSalesTimeBulk: reducerUtils.initial(),

  salesTimeUpdateModalOpen: false, // 판매시간설정 -> 1 항목 선택 후 수정 클릭시
  salesTimeUpdateModalData: null,

  refundModalOpen: false,
  refundModalData: null,
  cancelModalOpen: false,
  cancelModalData: {
    reason: "",
  },

  // 품목 추가, 품목 수정, 판매시간 등록 => 추가된 옵션 한개 설정 변경
  saleTemplateOptionChangeModalOpen: false,
  saleTemplateOptionChangeModalData: null,

  // 알람 팝업 창에서 조회 할 것
  getClinicNotificationListShort: reducerUtils.initial(),

  // 알람 상세 페이지 에서 조회 할 것
  clinicNotificationSearchOption: {
    page: 0,
    size: 50,
    searchType: "ALL",
  },
  getClinicNotificationList: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getClinicProduct", "getClinicProduct"),
    ...createSagaReducer("getClinicProductList", "getClinicProductList"),
    ...createSagaReducer("getReservation", "getReservation"),
    ...createSagaReducer("getReservationList", "getReservationList"),
    ...createSagaReducer("updateClinicProduct", "updateClinicProduct"),
    ...createSagaReducer("copyClinicProduct", "copyClinicProduct"),
    ...createSagaReducer("createClinicProduct", "createClinicProduct"),
    ...createSagaReducer("deleteClinicProduct", "deleteClinicProduct"),
    ...createSagaReducer("updateClinicProductOrder", "updateClinicProductOrder"),
    ...createSagaReducer("updateClinicProductDisplay", "updateClinicProductDisplay"),
    ...createSagaReducer("refundReservation", "refundReservation"),
    ...createSagaReducer("cancelReservation", "cancelReservation"),
    ...createSagaReducer("deleteReservation", "deleteReservation"),
    ...createSagaReducer("getSalesTimeList", "getSalesTimeList"),
    ...createSagaReducer("getSalesTime", "getSalesTime"),
    ...createSagaReducer("createSalesTime", "createSalesTime"),
    ...createSagaReducer("updateSalesTime", "updateSalesTime"),
    ...createSagaReducer("deleteSalesTime", "deleteSalesTime"),
    ...createSagaReducer("deleteSalesTimeBulk", "deleteSalesTimeBulk"),
    ...createSagaReducer("getClinicNotificationList", "getClinicNotificationList"),
    ...createSagaReducer("getClinicNotificationListShort", "getClinicNotificationListShort"),
    ...createSagaReducer("changeReservationAdminMemo", "changeReservationAdminMemo"),
    ...createSagaReducer("withdrawCancelReservation", "withdrawCancelReservation"),

    setIsNewNoti(state, action) {
      state.isNewNoti = action.payload;
    },
    setAdminCurrentStoreId(state, action) {
      state.admin_current_store_id = action.payload;
    },
    setReservationSelected(state, action) {
      state.reservationSelected = action.payload;
    },
    setClinicNotificationSearchOption(state, action) {
      const { key, value } = action.payload;
      let beforeState = { ...state.clinicNotificationSearchOption };
      beforeState = { ...beforeState, [key]: value };
      state.clinicNotificationSearchOption = beforeState;
    },
    resetClinicNotificationSearchOption(state) {
      state.clinicNotificationSearchOption = initialState.clinicNotificationSearchOption;
    },
    setResetCreateProduct(state) {
      state.createProduct = initialState.createProduct;
    },
    setSaleTemplateOptionChangeModalOpen(state, action) {
      state.saleTemplateOptionChangeModalOpen = action.payload;
    },
    setSaleTemplateOptionChangeModalData(state, action) {
      state.saleTemplateOptionChangeModalData = action.payload;
    },

    setCancelModalOpen(state, action) {
      state.cancelModalOpen = action.payload;
    },
    setCancelModalData(state, action) {
      state.cancelModalData = action.payload;
    },

    setRefundModalOpen(state, action) {
      state.refundModalOpen = action.payload;
    },
    setRefundModalData(state, action) {
      state.refundModalData = action.payload;
    },

    setSalesTimeUpdateModalOpen(state, action) {
      state.salesTimeUpdateModalOpen = action.payload;
    },
    setSalesTimeUpdateModalData(state, action) {
      state.salesTimeUpdateModalData = action.payload;
    },

    setInitItemTimeLineSelected(state) {
      (state.itemTimeLineSelectedProduct = "all"),
        (state.itemTimeLineSelectedProductInfo = {}),
        (state.itemTimeLineSelectedTime = [
          { startHour: "00", startMinute: "00", endHour: "01", endMinute: "00" },
        ]),
        (state.itemTimeLineSelectedPeriod = {
          startDate: "",
          endDate: "",
        }),
        (state.itemTimeLineSelectedDate = []),
        (state.itemTimeLineSelectedTemplate = null);
    },
    setItemTimeLineSelectedPeriod(state, action) {
      state.itemTimeLineSelectedPeriod = action.payload;
    },
    setItemTimeLineSelectedTemplate(state, action) {
      state.itemTimeLineSelectedTemplate = action.payload;
    },
    setItemTimeLineSelectedProduct(state, action) {
      state.itemTimeLineSelectedProduct = action.payload;
    },
    setItemTimeLineSelectedTime(state, action) {
      state.itemTimeLineSelectedTime = action.payload;
    },
    setItemTimeLineSelectedDate(state, action) {
      state.itemTimeLineSelectedDate = action.payload;
    },
    setStoreInfoResponseBuilder(state, action) {
      state.store_info_response_builder = action.payload;
    },
    setGetClinicProductListPageable(state, action) {
      state.getClinicProductPageable = action.payload;
    },
    setCreateProduct(state, action) {
      const { key, value } = action.payload;
      let beforeState = { ...state.createProduct };
      beforeState = { ...beforeState, [key]: value };
      state.createProduct = beforeState;
    },
    setItemListSearchOption(state, action) {
      const { key, value } = action.payload;
      let beforeState = { ...state.itemListSearchOption };
      beforeState = { ...beforeState, [key]: value };
      state.itemListSearchOption = beforeState;
    },
    setSearchOptions(state, action) {
      const { key, value } = action.payload;
      let beforeState = { ...state.searchOptions };
      beforeState = { ...beforeState, [key]: value };

      state.searchOptions = beforeState;
    },
    setReservationSearchOption(state, action) {
      const { key, value } = action.payload;
      let beforeState = { ...state.reservationSearchOption };
      beforeState = { ...beforeState, [key]: value };

      state.reservationSearchOption = beforeState;
    },
    resetReservationSearchOption(state) {
      state.reservationSearchOption = initialState.reservationSearchOption;
    },
    setOrderModalOpen(state, action) {
      state.orderModalOpen = action.payload;
    },
    setOrderModalData(state, action) {
      state.orderModalData = action.payload;
    },
    setCreateReservationModalOpen(state, action) {
      state.createReservationModalOpen = action.payload;
    },
    setCreateReservationModalData(state, action) {
      state.createReservationModalData = action.payload;
    },
    setGetClinicProduct(state, action) {
      const { key, value } = action.payload;
      let beforeState = { ...state.getClinicProduct.data };
      beforeState = { ...beforeState, [key]: value };
      state.getClinicProduct.data = beforeState;
    },
    // 관리자 메모 때문에
    setGetReservation(state, action) {
      const { key, value } = action.payload;
      let beforeState = { ...state.getReservation.data };
      beforeState = { ...beforeState, [key]: value };
      state.getReservation.data = beforeState;
    },
  },
});

export const CLINIC = slice.name;
export const clinicReducer = slice.reducer;
export const clinicAction = slice.actions;
