import { takeLatest, put, all, fork, select, delay } from "redux-saga/effects";
import * as recenttrackingApi from "@store/recenttracking/recenttrackingApi";
import Swal from "sweetalert2";
import { recenttrackingAction } from "./recenttrackingSlice";

function* getRecentTrackingListSaga(action) {
  try {
    const { userToken, searchParams } = action.payload;
    const response = yield recenttrackingApi.getRecentTrackingListApi({
      userToken,
      searchParams,
    });
    if (response.status_code === 1) {
      yield put(recenttrackingAction.getRecentTrackingListSuccess(response.data));
    } else {
      yield put(recenttrackingAction.getRecentTrackingListError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getRecentTrackingDetailListSaga(action) {
  try {
    const { userToken, searchParams } = action.payload;
    const response = yield recenttrackingApi.getRecentTrackingDetailListApi({
      userToken,
      searchParams,
    });
    if (response.status_code === 1) {
      yield put(recenttrackingAction.getRecentDetailListSuccess(response.data));
      yield put(recenttrackingAction.setRecentDetailListPopupOpen(true));
    } else {
      yield put(recenttrackingAction.getRecentDetailListError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* exportRecentTrackingListSaga(action) {
  try {
    const { userToken, type, searchParams } = action.payload;
    const response = yield recenttrackingApi.exportExcelRecentTrakingApi({
      userToken,
      type,
      searchParams,
    });
    if (response) {
      yield put(recenttrackingAction.exportRecentTrackingListSuccess());
    }
  } catch (err) {
    console.log(err);
  }
}

function* watchExportRecentTrackingListSaga() {
  yield takeLatest(recenttrackingAction.exportRecentTrackingList, exportRecentTrackingListSaga);
}

function* watchGetRecentTrackingSaga() {
  yield takeLatest(recenttrackingAction.getRecentTrackingList, getRecentTrackingListSaga);
}

function* watchGetRecentDetailListSaga() {
  yield takeLatest(recenttrackingAction.getRecentDetailList, getRecentTrackingDetailListSaga);
}

export function* recentTrackingSaga() {
  yield all([
    fork(watchGetRecentTrackingSaga),
    fork(watchExportRecentTrackingListSaga),
    fork(watchGetRecentDetailListSaga),
  ]);
}
