import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";
import { set } from "lodash";

const name = "sms";

const initialState = {
  getSmsTemplateList: reducerUtils.initial(),
  getSmsTemplate: reducerUtils.initial(),
  saveSmsTemplate: reducerUtils.initial(),
  deleteSmsTemplate: reducerUtils.initial(),
  sendSms: reducerUtils.initial(),
  getSmsHistoryList: reducerUtils.initial(),
  resendSms: reducerUtils.initial(),

  sms_template_search_option: {
    searchType: "SMS_TEMPLATE_ALL", // SMS_TEMPLATE_ITEM_NAME
    send_type: "all", // all || sms || email || kakao
    searchValue: "",
    page: 0,
    size: 20,
  },
  create_sms_template: {
    sms_send_type: "sms", // sms || email || kakao
    sms_template_ttl: "",
    sms_template_desc: "",
    fix_receiver_no_list: [],
    sms_send_point: "dispatchOnSale",
    auto_send_yn: true,
    auto_send_item_list: [],
    auto_send_condition: "order_complete", // 주문 승인 요청, 주문 취소 요청, 주문 승인 완료, 주문 취소 완료
    sms_template_send_ttl: "",
    sms_template_send_cnts: "",
    // send_images: [],
  },

  smsModalOpen: false,
  smsModalData: {
    sms_template_id: "null",
    sms_ttl: "",
    sms_cnts: "",
    receiver_no_list: [],
    sms_send_type: "sms",
  },

  smsHistorySearchOption: {
    searchType: 0,
    page: 0,
    size: 20,
  },

  smsDetailModalOpen: false,
  smsDetailModalData: null,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getSmsTemplateList", "getSmsTemplateList"),
    ...createSagaReducer("getSmsTemplate", "getSmsTemplate"),
    ...createSagaReducer("saveSmsTemplate", "saveSmsTemplate"),
    ...createSagaReducer("deleteSmsTemplate", "deleteSmsTemplate"),
    ...createSagaReducer("sendSms", "sendSms"),
    ...createSagaReducer("getSmsHistoryList", "getSmsHistoryList"),
    ...createSagaReducer("resendSms", "resendSms"),

    setSmsDetailModalOpen: (state, action) => {
      state.smsDetailModalOpen = action.payload;
    },
    setSmsDetailModalData: (state, action) => {
      state.smsDetailModalData = action.payload;
    },
    setSmsHistorySearchOption: (state, action) => {
      const { key, value } = action.payload;
      let beforeState = { ...state.smsHistorySearchOption };
      beforeState = { ...beforeState, [key]: value };
      state.smsHistorySearchOption = beforeState;
    },
    setSmsModalOpen: (state, action) => {
      state.smsModalOpen = action.payload;
    },
    setSmsModalData: (state, action) => {
      state.smsModalData = action.payload;
    },
    setSmsTemplateSearchOption: (state, action) => {
      const { key, value } = action.payload;
      let beforeState = { ...state.sms_template_search_option };
      beforeState = { ...beforeState, [key]: value };
      state.sms_template_search_option = beforeState;
    },
    setCreateSmsTemplate: (state, action) => {
      const { key, value } = action.payload;
      let beforeState = { ...state.create_sms_template };
      beforeState = { ...beforeState, [key]: value };
      state.create_sms_template = beforeState;
    },
    //  getSmsTemplate의 data 부분을 수정하는 액션
    setGetSmsTemplate: (state, action) => {
      const { key, value } = action.payload;
      if (state.getSmsTemplate.data) {
        state.getSmsTemplate.data[key] = value;
      }
    },
    setInitCreateSmsTemplate: (state) => {
      state.create_sms_template = initialState.create_sms_template;
    },
  },
});

export const SMS = slice.name;
export const smsReducer = slice.reducer;
export const smsAction = slice.actions;
