import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";
import dayjs from "dayjs";

const name = "frequencytracking";

const initialState = {
  str_date: dayjs().subtract(2, "month").format("YYYYMMDD"), // 5년 전
  end_date: dayjs().add(5, "year").format("YYYYMMDD"), // 5년 후

  page: 0,
  size: 50,
  popupPage: 0,
  popupSize: 50,
  frequencyDetailListPopup: {
    open: false,
    data: null,
  },

  searchType: "customer_name",
  searchValue: "",
  searchOptionList: {
    is_customer: "true",
  },

  getFrequencyTrackingList: reducerUtils.initial(),
  getFrequencyDetailList: reducerUtils.initial(),
  exportFrequencyTrackingList: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getFrequencyTrackingList", "getFrequencyTrackingList"),
    ...createSagaReducer("exportFrequencyTrackingList", "exportFrequencyTrackingList"),
    ...createSagaReducer("getFrequencyDetailList", "getFrequencyDetailList"),
    setSearchType(state, action) {
      state.searchType = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setSearchOptionList(state, action) {
      state.searchOptionList = action.payload;
    },
    setFrequencyDetailListPopup(state, action) {
      state.frequencyDetailListPopup = action.payload;
    },
    setFrequencyDetailListPopupOpen(state, action) {
      state.frequencyDetailListPopup.open = action.payload;
    },
    setFrequencyDetailListPopupData(state, action) {
      state.frequencyDetailListPopup.data = action.payload;
    },
    setPopupPage(state, action) {
      state.popupPage = action.payload;
    },
    setPopupSize(state, action) {
      state.popupSize = action.payload;
    },
    setStrDate(state, action) {
      state.str_date = action.payload;
    },
    setEndDate(state, action) {
      state.end_date = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSize(state, action) {
      state.size = action.payload;
    },
    setSearchType(state, action) {
      state.searchType = action.payload;
    },
    setSearchOption(state, action) {
      state.searchOption = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    initFrequencySlice(state) {
      state.searchType = "customerId";
      state.searchValue = "";
      state.strDate = dayjs().subtract(5, "year").format("YYMMDD");
      state.endDate = dayjs().add(5, "year").format("YYMMDD");
      state.page = 0;
      state.size = 100;
      state.popupPage = 0;
      state.popupSize = 1;
      state.recentDetailListPopup = {
        open: false,
        data: null,
      };
      state.getFrequencyTrackingList = reducerUtils.initial();
      state.getFrequencyDetailList = reducerUtils.initial();
      state.exportFrequencyTrackingList = reducerUtils.initial;
    },
  },
  reset: (state) => {},
});

export const FREQUENCYTRACKING = slice.name;
export const frequencytrackingReducer = slice.reducer;
export const frequencytrackingAction = slice.actions;
