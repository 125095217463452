import { takeLatest, put, all, fork, select, delay } from "redux-saga/effects";
import { commonCodeAction } from "@store/commonCode/commonCodeSlice";
import * as smsApi from "./smsApi";
import createAsyncSaga from "@store/saga.util";
import { smsAction } from "./smsSlice";
import Swal from "sweetalert2";
import { isAdmin } from "@utils/helper";
import { jwtDecode } from "jwt-decode";

function* saveSmsTemplateSaga(action) {
  try {
    const { userToken, data, router } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield smsApi.saveSmsTemplateApi({
      userToken,
      store_current_id,
      data,
    });
    if (response.status_code === 1) {
      yield put(smsAction.saveSmsTemplateSuccess(response));
      Swal.fire({
        title: "템플릿이 저장되었습니다.",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          router.replace("/clinic/sms/template");
        }
      });
    } else {
      yield put(smsAction.saveSmsTemplateFailure(response));
      Swal.fire({
        title: "템플릿 저장에 실패하였습니다.",
        icon: "error",
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getSmsTemplateListSaga(action) {
  try {
    const { userToken, storeId, page, size, searchType, searchValue } = action.payload;

    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield smsApi.getSmsTemplateListApi({
      userToken,
      store_current_id,
      storeId,
      page,
      size,
      searchType,
      searchValue,
    });
    if (response.status_code === 1) {
      yield put(smsAction.getSmsTemplateListSuccess(response));
    } else {
      yield put(smsAction.getSmsTemplateListError(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getSmsTemplateSaga(action) {
  try {
    const { userToken, templateId } = action.payload;

    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield smsApi.getSmsTemplateApi({
      userToken,
      store_current_id,
      templateId,
    });
    if (response.status_code === 1) {
      yield put(smsAction.getSmsTemplateSuccess(response.data));
    } else {
      yield put(smsAction.getSmsTemplateError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* deleteSmsTemplateSaga(action) {
  try {
    const { page, size, searchType, searchValue } = yield select(
      (state) => state.sms.sms_template_search_option
    );
    const { userToken, templateId, storeId } = action.payload;

    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield smsApi.deleteSmsTemplateApi({
      userToken,
      templateId,
      store_current_id,
    });
    if (response.status_code === 1) {
      yield put(smsAction.deleteSmsTemplateSuccess(response));
      Swal.fire({
        title: "템플릿이 삭제되었습니다.",
        icon: "success",
      });
      yield put(
        smsAction.getSmsTemplateList({
          userToken,
          storeId,
          page,
          size,
          searchType,
          searchValue,
        })
      );
    } else {
      yield put(smsAction.deleteSmsTemplateError(response));
      Swal.fire({
        title: "템플릿 삭제에 실패하였습니다.",
        icon: "error",
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* sendSmsSaga(action) {
  try {
    const { userToken, data } = action.payload;

    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield smsApi.sendSmsApi({
      userToken,
      store_current_id,
      data,
    });
    if (response.status_code === 1) {
      yield put(smsAction.sendSmsSuccess(response));
      Swal.fire({
        title: "SMS 발송 완료",
        icon: "success",
      });
      yield put(smsAction.setSmsModalOpen(false));
    } else {
      yield put(smsAction.sendSmsError(response));
      Swal.fire({
        title: "SMS 발송 실패",
        icon: "error",
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getSmsHistoryListSaga(action) {
  try {
    const { userToken } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield smsApi.getSmsHistoryListApi({ ...action.payload, store_current_id });
    if (response.status_code === 1) {
      yield put(smsAction.getSmsHistoryListSuccess(response.data));
    } else {
      yield put(smsAction.getSmsHistoryListError(response));
    }
  } catch (err) {
    yield put(smsAction.getSmsHistoryListError(err));
    console.log(err);
  }
}

function* resendSmsSaga(action) {
  try {
    const { userToken, id } = action.payload;
    const checkAdmin = isAdmin(userToken);
    const admin_current_store_id = yield select((state) => state.clinic.admin_current_store_id);
    const store_current_id = checkAdmin
      ? admin_current_store_id
      : jwtDecode(action.payload.userToken).storeInfo.id;

    const response = yield smsApi.resendSmsApi({
      userToken,
      store_current_id,
      id,
    });
    if (response.status_code === 1) {
      yield put(smsAction.resendSmsSuccess(response));
      Swal.fire({
        title: "SMS 재전송 완료",
        icon: "success",
      });
    } else {
      yield put(smsAction.resendSmsError(response));
      Swal.fire({
        title: "SMS 재전송 실패",
        icon: "error",
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* watchGetSmsHistoryListSaga() {
  yield takeLatest(smsAction.getSmsHistoryList.type, getSmsHistoryListSaga);
}

function* watchResendSmsSaga() {
  yield takeLatest(smsAction.resendSms.type, resendSmsSaga);
}

function* watchSendSmsSaga() {
  yield takeLatest(smsAction.sendSms.type, sendSmsSaga);
}

function* watchSaveSmsTemplateSaga() {
  yield takeLatest(smsAction.saveSmsTemplate.type, saveSmsTemplateSaga);
}

function* watchGetSmsTemplateListSaga() {
  yield takeLatest(smsAction.getSmsTemplateList.type, getSmsTemplateListSaga);
}

function* watchGetSmsTemplateSaga() {
  yield takeLatest(smsAction.getSmsTemplate.type, getSmsTemplateSaga);
}

function* watchDeleteSmsTemplateSaga() {
  yield takeLatest(smsAction.deleteSmsTemplate.type, deleteSmsTemplateSaga);
}

export function* smsSaga() {
  yield all([
    fork(watchSaveSmsTemplateSaga),
    fork(watchGetSmsTemplateListSaga),
    fork(watchGetSmsTemplateSaga),
    fork(watchDeleteSmsTemplateSaga),
    fork(watchSendSmsSaga),
    fork(watchGetSmsHistoryListSaga),
    fork(watchResendSmsSaga),
  ]);
}
