import axios from "axios";
import { HeaderBasic, HeaderFormData } from "@store/api.util";

export const uploadFile = async ({ userToken, formData }) => {
  return axios
    .post(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/files`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      return Error;
    });
};

export const updateCommFileGrpDelYn = async ({ userToken, data }) => {
  return axios
    .put(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/files`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      return Error;
    });
};

export const getCommFileGrps = async ({ userToken, input_type, user_id }) => {
  console.log(input_type, user_id);
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/files`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
      params: {
        input_type,
        user_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      return Error;
    });
};
