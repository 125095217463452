import axios from "axios";
export const getCartTrackingListApi = async ({ userToken, params }) => {
  const { page, size, ...filteredParams } = params;

  return axios
    .post(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/carts/statics?page=${page}&size=${size}`,
      filteredParams, // ✅ page/size는 body에서 빠짐
      {
        headers: {
          "Authorization-Play": `Bearer ${userToken}`,
          Accept: "application/json",
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const getCartTrackingDetailApi = async ({ userToken, type, id, page, size }) => {
  console.log("type", type);
  const url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/carts/statics/${
    type === "product" ? "products" : "customers"
  }/${id}?page=${page}&size=${size}`;

  return axios
    .get(url, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

// type: 0 인 전체 페이지에서 다운로드 하는것
export const exportExcelCartTrakingApi = async ({
  userToken,
  searchType, // customerId || productId
  searchValue,
  orderStatus,
}) => {
  const url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/carts/statics/download/excel/0`;
  return axios
    .post(
      url,
      {
        [searchType]: searchValue,
        orderStatus,
      },
      {
        responseType: "blob",
        headers: {
          "Authorization-Play": `Bearer ${userToken}`,
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      // `Content-Disposition`에서 파일명 추출
      const disposition = response.headers["content-disposition"];
      let fileName = "download.xlsx";
      if (disposition && disposition.includes("filename*=")) {
        const match = disposition.match(/filename\*=(?:UTF-8'')?(.+)/);

        if (match?.[1]) {
          fileName = decodeURIComponent(match[1]); // UTF-8 디코딩
        }
      }

      // Blob을 생성하고 URL을 만들어 자동 다운로드 실행
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return true;
    })
    .catch((err) => {
      console.error("파일 다운로드 실패", err);
      return false;
    });
};

export const exportExcelCartTrackingDetailApi = async ({
  userToken,
  orderStatus,
  type,
  searchType,
  id,
}) => {
  const url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/carts/statics/download/excel/${type}`;

  return axios
    .post(
      url,
      { [searchType]: id, orderStatus },
      {
        responseType: "blob",
        headers: {
          "Authorization-Play": `Bearer ${userToken}`,
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      // `Content-Disposition`에서 파일명 추출
      const disposition = response.headers["content-disposition"];
      let fileName = "download.xlsx";
      if (disposition && disposition.includes("filename*=")) {
        const match = disposition.match(/filename\*=(?:UTF-8'')?(.+)/);

        if (match?.[1]) {
          fileName = decodeURIComponent(match[1]); // UTF-8 디코딩
        }
      }

      // Blob을 생성하고 URL을 만들어 자동 다운로드 실행
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((err) => {
      console.error("파일 다운로드 실패", err);
    });
};
