import * as rewardApi from "./rewardApi";
import { takeLatest, put, all, fork, select, delay, call } from "redux-saga/effects";
import { rewardAction } from "./rewardSlice";
import Swal from "sweetalert2";

function* getRewardListSaga(action) {
  try {
    const { userToken, page, size } = action.payload;
    const response = yield call(rewardApi.getRewardListApi, {
      userToken,
      page,
      size,
    });
    if (response && response.status_code) {
      console.log(response);
      yield put(rewardAction.getRewardListSuccess(response.data));
    } else {
      yield put(rewardAction.getRewardListError(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getRewardGroupListSaga(action) {
  try {
    const { userToken } = action.payload;
    const response = yield call(rewardApi.getRewardGroupListApi, {
      userToken,
    });
    if (response && response.status_code) {
      yield put(rewardAction.getRewardGroupListSuccess(response.data));
    } else {
      yield put(rewardAction.getRewardGroupListError(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* CreateRewardSaga(action) {
  try {
    const { userToken, data } = action.payload;
    const response = yield call(rewardApi.createRewardApi, {
      userToken,
      data,
    });
    if (response && response.status_code) {
      yield put(rewardAction.createRewardSuccess(response.data));
      Swal.fire({
        icon: "success",
        title: "성공",
        text: "리워드가 생성되었습니다.",
      });
      yield call(getRewardListSaga, { payload: { userToken } });
      yield put(rewardAction.setCreateRewardModalOpen(false));
    } else {
      yield put(rewardAction.createRewardError(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateRewardSaga(action) {
  try {
    const { userToken, data } = action.payload;
    const response = yield call(rewardApi.updateRewardApi, {
      userToken,
      data,
    });
    if (response && response.status_code) {
      yield put(rewardAction.updateRewardSuccess(response.data));
      Swal.fire({
        icon: "success",
        title: "성공",
        text: "리워드가 수정되었습니다.",
      });
      yield put(rewardAction.setUpdateRewardModalOpen(false));
      yield put(rewardAction.setInitUpdateRewardData());
      yield call(getRewardListSaga, { payload: { userToken } });
    } else {
      yield put(rewardAction.updateRewardError(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* deleteRewardSaga(action) {
  try {
    const { userToken, rewardId } = action.payload;
    const response = yield call(rewardApi.deleteRewardApi, {
      userToken,
      rewardId,
    });
    if (response && response.status_code) {
      yield put(rewardAction.deleteRewardSuccess(response.data));
      Swal.fire({
        icon: "success",
        title: "성공",
        text: "리워드가 삭제되었습니다.",
      });
      yield call(getRewardListSaga, { payload: { userToken } });
    } else {
      yield put(rewardAction.deleteRewardError(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* CreateRewardGroupSaga(action) {
  try {
    const { userToken, data } = action.payload;
    const response = yield call(rewardApi.createRewardGroupApi, {
      userToken,
      data,
    });
    if (response && response.status_code) {
      yield put(rewardAction.createRewardGroupSuccess(response.data));
      Swal.fire({
        icon: "success",
        title: "성공",
        text: "리워드 그룹이 생성되었습니다.",
      });
      yield put(rewardAction.setManageRewardGroupModalOpen(false));
      yield put(rewardAction.setInitCreateGroupData());
    } else {
      yield put(rewardAction.createRewardGroupError(response));
    }
  } catch (err) {
    console.log(err);
  }
}
function* UpdateRewardGroupSaga(action) {
  try {
    const { userToken, data } = action.payload;
    const response = yield call(rewardApi.updateRewardGroupApi, {
      userToken,
      data,
    });

    if (response && response.status_code) {
      yield put(rewardAction.updateRewardGroupSuccess(response.data));

      Swal.fire({
        icon: "success",
        title: "성공",
        text: "리워드 그룹이 수정되었습니다.",
      });

      yield put(rewardAction.setUpdateRewardGroupModalOpen(false));

      // ✅ 여기서 바로 getRewardGroupListSaga 호출
      yield call(getRewardGroupListSaga, { payload: { userToken } });
    } else {
      yield put(rewardAction.updateRewardGroupError(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* watchGetRewardList() {
  yield takeLatest(rewardAction.getRewardList, getRewardListSaga);
}

function* watchGetGroupList() {
  yield takeLatest(rewardAction.getRewardGroupList, getRewardGroupListSaga);
}

function* watchCreateReward() {
  yield takeLatest(rewardAction.createReward, CreateRewardSaga);
}

function* watchUpdateReward() {
  yield takeLatest(rewardAction.updateReward, updateRewardSaga);
}

function* watchCreateRewardGroup() {
  yield takeLatest(rewardAction.createRewardGroup, CreateRewardGroupSaga);
}

function* watchUpdateRewardGroup() {
  yield takeLatest(rewardAction.updateRewardGroup, UpdateRewardGroupSaga);
}

function* watchDeleteReward() {
  yield takeLatest(rewardAction.deleteReward, deleteRewardSaga);
}

export function* rewardSaga() {
  yield all([
    fork(watchGetRewardList),
    fork(watchGetGroupList),
    fork(watchCreateReward),
    fork(watchUpdateReward),
    fork(watchDeleteReward),
    fork(watchCreateRewardGroup),
    fork(watchUpdateRewardGroup),
  ]);
}
