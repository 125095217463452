import { createSlice } from "@reduxjs/toolkit";
import { reducerUtils } from "@store/reducer.util";
import { createSagaReducer } from "@store/saga.util";

const name = "point";

const initialState = {
  response: reducerUtils.initial(),
  getUserPointAmount: reducerUtils.initial(),
  getUserPointEvent: reducerUtils.initial(),
  getUserPointList: reducerUtils.initial(),
  getPointTotal: reducerUtils.initial(),

  pushReward: reducerUtils.initial(),
  deleteReward: reducerUtils.initial(),
  getRewardList: reducerUtils.initial(),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    ...createSagaReducer("getUserPointAmount", "getUserPointAmount"),
    ...createSagaReducer("getUserPointEvent", "getUserPointEvent"),
    ...createSagaReducer("getUserPointList", "getUserPointList"),
    ...createSagaReducer("getPointTotal", "getPointTotal"),

    ...createSagaReducer("pushReward", "pushReward"),
    ...createSagaReducer("deleteReward", "deleteReward"),
    ...createSagaReducer("getRewardList", "getRewardList"),

    reset: (state, action) => {
      state.getUserPointAmount = reducerUtils.initial();
      state.getUserPointEvent = reducerUtils.initial();
      state.getUserPointList = reducerUtils.initial();
      state.getPointTotal = reducerUtils.initial();
    },
  },
});

export const POINT = slice.name;
export const pointReducer = slice.reducer;
export const pointAction = slice.actions;
