import { takeLatest, put, all, fork, select, delay, call } from "redux-saga/effects";
import createAsyncSaga from "../saga.util";
import {
  ORDER_ITEM_KNOLLO_VENDOR,
  ORDER_SEARCH_PRODUCT_NAME,
  ORDER_STATUS,
} from "@constants/order";
import { snackbarAction } from "@store/snackbar/snackbarSlice";
import { salescheckAction } from "./salescheckSlice";
import * as salesApi from "./salescheckApi";
import * as orderApi from "../order/orderApi";

function* getAllOrdersAndCalcSaga() {
  try {
    const searchOptions = yield select((state) => state.salescheck.searchOptions);
    const productName = yield select((state) => state.salescheck[ORDER_SEARCH_PRODUCT_NAME]);
    const selectedDateIndex = yield select((state) => state.salescheck.selectedDateIndex);

    let payload = {
      ...searchOptions,
      status: [
        "paid",
        "under-paid",
        "over-paid",
        "partially-refunded",
        "under-refunded",
        "over-refunded",
      ],
    };
    // 상품명 검색이 존재한다면 param에 추가
    if (productName) payload[ORDER_SEARCH_PRODUCT_NAME] = productName;
    const res = yield call(orderApi.getOrderCount, { ...payload, searchMatch: "partial" });

    const PAGE_LIMIT = 100;
    let totalItemLength = res.count.raw;
    let lastPage = Math.floor(totalItemLength / PAGE_LIMIT) + 1;

    let allOrders = [];
    let totalOrderAmount = 0; // 총 건수
    let totalOrderPaidAmount = 0; // 총 금액
    let totalOrderPaidAmountKnollo = 0; //총 놀로 판매 금액
    let totalOrderPaidAmountNotKnollo = 0; //총 놀로 외 판매 금액
    let totalAmountSearchProduct = 0; // 검색 상품에 대한 총 금액

    let chartItems = [];
    let charItem = {};

    for (let i = 1; i <= lastPage; i++) {
      let data = yield call(salesApi.getOrders, { ...payload, page: i, limit: PAGE_LIMIT });
      allOrders = [...allOrders, ...data];
      let existItem = false;
      data.forEach((orderItem) => {
        const createdAt = orderItem.createdAt.formatted;

        // 차트 데이터 생성 로직
        let charItemFormat;
        if (selectedDateIndex >= 5 || selectedDateIndex === -1) {
          charItemFormat = createdAt.match(/\d{4}년 \d{1,2}월/)[0]; // 월별로 변환
        } else {
          charItemFormat = createdAt.match(/\d{4}년 \d{1,2}월 \d{1,2}일/)[0]; // 날짜별 유지
        }

        // 수량 및 금액 데이터 누적
        if (!charItem[charItemFormat]) {
          charItem[charItemFormat] = { count: 0, amount: 0 };
        }
        charItem[charItemFormat].count++;
        charItem[charItemFormat].amount += orderItem.total.amount.raw;

        const existingChartItem = chartItems.find((item) => item.id === charItemFormat);
        if (existingChartItem) {
          existingChartItem.value = charItem[charItemFormat].count;
          existingChartItem.amount = charItem[charItemFormat].amount;
        } else {
          chartItems.push({
            id: charItemFormat,
            label: charItemFormat,
            value: charItem[charItemFormat].count,
            amount: charItem[charItemFormat].amount,
          });
        }

        // 매출 데이터 생성 로직
        let items = orderItem.items;
        totalOrderPaidAmount += orderItem.total.amount.raw;
        for (let j = 0; j < items.length; j++) {
          if (items[j].product.name.includes(productName)) {
            existItem = true;
          }
          if (items[j].vendor?._id === process.env.NEXT_PUBLIC_ORDER_VENDOR) {
            totalOrderPaidAmountKnollo += items[j].price.sale.raw;
          } else {
            totalOrderPaidAmountNotKnollo += items[j].price.sale.raw;
          }
          if (items[j].product.name.includes(productName)) {
            totalAmountSearchProduct += items[j].price.sale.raw;
          }
        }
        if (existItem) {
          totalOrderAmount++;
          existItem = false;
          totalOrderPaidAmountKnollo += orderItem.total.shipping?.fee?.sale?.raw;
        } else {
          totalOrderPaidAmountNotKnollo += orderItem.total.shipping?.fee?.sale?.raw;
        }
      });
    }

    // 결과 저장
    yield put(
      salescheckAction.setSalesResult({
        "총 주문건수": totalOrderAmount,
        "총 주문금액": totalOrderPaidAmount,
        "놀로상품 주문금액": totalOrderPaidAmountKnollo,
        "놀로외상품 주문금액": totalOrderPaidAmountNotKnollo,
        "검색상품 주문금액": totalAmountSearchProduct,
      })
    );

    yield put(salescheckAction.setSalesChartData(chartItems.reverse()));
    yield put(salescheckAction.getOrdersSuccess(allOrders));
  } catch (err) {
    yield put(
      snackbarAction.setSnackbar({
        isOpen: true,
        type: "error",
        message: "매출조회에 실패하였습니다.",
      })
    );
    yield put(salescheckAction.getOrdersError(err));
  }
}

function* watchGetOrdersSaga() {
  yield takeLatest(salescheckAction.getOrders, getAllOrdersAndCalcSaga);
}

export function* salescheckSaga() {
  yield all([fork(watchGetOrdersSaga)]);
}
