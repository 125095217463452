import axios from "axios";
import { HeaderBasic } from "@store/api.util";

// product 리스트 조회
export const getClinicProductList = async ({ userToken, store_current_id, page, size, sort }) => {
  return axios
    .get(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods?page=${page}&size=${size}`,
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getClinicProductListSearch = async ({
  userToken,
  store_current_id,
  searchType,
  searchText,
}) => {
  return axios
    .get(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods?searchType=${searchType}&searchValue=${searchText}`,
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// product 1개 조회
export const getClinicProduct = async ({ userToken, store_current_id, clinicId }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods/${clinicId}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};
// product 1개 수정
export const updateClinicProduct = async ({ userToken, store_current_id, data }) => {
  console.log("update clinic api");
  return axios
    .put(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods`, // URL
      data, // Body에 담길 데이터
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      console.error("Error updating clinic product:", error); // 에러 로깅
      throw error; // 에러를 상위로 전달
    });
};

// product 1개 삭제
export const deleteClinicProduct = async ({ userToken, store_current_id, clinicId }) => {
  return axios
    .delete(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods/${clinicId}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": `Bearer ${userToken}`,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.log("Error:", error);
    });
};

// product 1개 복제
export const copyClinicProduct = async ({ userToken, store_current_id, clinicId }) => {
  return axios
    .post(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods/copy/${clinicId}`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// product 1개 생성
export const createClinicProduct = async ({ userToken, store_current_id, clinicData }) => {
  return axios
    .post(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods`,
      {
        ...clinicData,
      },
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// product 순서 변경
export const updateClinicProductOrder = async ({ userToken, store_current_id, data }) => {
  return axios
    .put(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods/sort`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// product 노출 변경
export const updateClinicProductDisplay = async ({ userToken, store_current_id, id }) => {
  return axios
    .put(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods/display/${id}`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 품목 사진정보 임시 업로드
export const uploadTempImage = async ({ formData, userToken }) => {
  console.log(formData);
  return axios
    .post(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods/upload/temp`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 품목 사진정보 삭제
export const deleteTempImage = async ({ userToken, store_current_id, fileGroupId, fileId }) => {
  return axios
    .delete(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/goods/upload/temp/${fileGroupId}/${fileId}`,
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 예약정보 리스트 조회
export const getReservationList = async ({ userToken, store_current_id, page, size, ...etc }) => {
  const etcParams = new URLSearchParams(etc).toString();
  return axios
    .get(
      `${
        process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST
      }/admin/booking/sales?page=${page}&size=${size}${etcParams ? `&${etcParams}` : ""}`,
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 예약정보 1개 조회
export const getReservation = async ({ userToken, store_current_id, id }) => {
  console.log("here");
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/sales/${id}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 예약정보 1개 삭제
export const deleteReservation = async ({ userToken, store_current_id, id }) => {
  return axios
    .delete(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/sales/${id}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 예약정보 1개 환불
export const refundReservation = async (payload) => {
  return axios
    .put(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/sales/refund`,
      payload.data,
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + payload.userToken,
          "Store-Id": payload.store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 예약정보 1개 취소
export const cancelReservation = async ({ userToken, store_current_id, id }) => {
  return axios
    .put(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/sales/cancel/${id}`,
      {},
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 예약정보 1개 수기등록

// ******** 판매시간 등록 관련

// 판매시간 설정 전체 조회
export const getSalesTimeList = async ({
  userToken,
  store_current_id,
  storeId,
  today,
  searchType,
  searchValue,
}) => {
  const url =
    searchType === "all" || searchType === "CALENDAR_ALL" || !searchType
      ? `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/products/${store_current_id}?today=${today}`
      : `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/products/${store_current_id}?today=${today}&searchType=${searchType}&searchValue=${searchValue}`;
  return axios
    .get(url, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 판매시간 설정 1개 조회
export const getSalesTime = async ({ userToken, store_current_id, id }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/products/${id}/details`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 판매시간 설정 등록
export const createSalesTime = async ({ userToken, store_current_id, data }) => {
  return axios
    .post(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/products`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 판매시간 설정 수정
export const updateSalesTime = async ({ userToken, store_current_id, data }) => {
  return axios
    .put(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/products`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// 판매시간 설정 삭제
export const deleteSalesTime = async ({ userToken, store_current_id, id }) => {
  return axios
    .delete(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/products/${id}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const deleteSalesTimeBulk = async ({ userToken, store_current_id, ids }) => {
  return axios
    .delete(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/products/bulk`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
      data: {
        id: ids,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

// notificaiton 리스트 조회
export const getNotificationList = async ({
  userToken,
  store_current_id,
  storeId,
  page,
  size,
  searchValue,
}) => {
  const url = searchValue
    ? `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/notifications/${store_current_id}?page=${page}&size=${size}&searchType=NOTIFICATION_CATEGORY&searchValue=${searchValue}`
    : `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/notifications/${store_current_id}?page=${page}&size=${size}`;
  return axios
    .get(url, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
        "Store-Id": store_current_id,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const changeReservationAdminMemoApi = async ({
  userToken,
  book_mst_id,
  store_current_id,
  book_admin_memo,
}) => {
  return axios
    .put(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/sales/memo`,
      {
        book_mst_id,
        book_admin_memo,
      },
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const withdrawCancelReservation = async ({
  userToken,
  store_current_id,
  book_mst_id,
  item_mst_id,
}) => {
  // console.log(book_mst_id);
  // return;
  return axios
    .put(
      `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/booking/sales/refund/cancel`,
      {
        book_mst_id,
      },
      {
        headers: {
          Accept: "application/json",
          "Authorization-Play": "Bearer " + userToken,
          "Store-Id": store_current_id,
        },
      }
    )
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};
