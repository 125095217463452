import axios from "axios";

export const getRewardGroupListApi = async ({ userToken }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward/group/list`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const getRewardListApi = async ({ userToken, page, size }) => {
  return axios
    .get(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward/list`, {
      params: {
        page,
        size,
      },
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const createRewardApi = async ({ userToken, data }) => {
  return axios
    .post(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const updateRewardApi = async ({ userToken, data }) => {
  return axios
    .put(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const deleteRewardApi = async ({ userToken, rewardId }) => {
  return axios
    .delete(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward/${rewardId}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const createRewardGroupApi = async ({ userToken, data }) => {
  return axios
    .post(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward/group`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const updateRewardGroupApi = async ({ userToken, data }) => {
  return axios
    .put(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward/group`, data, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};

export const deleteRewardGroupApi = async ({ userToken, groupId }) => {
  return axios
    .delete(`${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/reward/group/${groupId}`, {
      headers: {
        Accept: "application/json",
        "Authorization-Play": "Bearer " + userToken,
      },
    })
    .then((res) => res.data)
    .catch((Error) => {
      console.log(Error);
    });
};
