import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { snackbarAction } from "@store/snackbar/snackbarSlice";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarComponent = () => {
  const dispatch = useDispatch();
  const { isOpen, type, message, infinity = false } = useSelector((state) => state.snackbar);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(snackbarAction.resetSnackbar());
  };

  const handleExited = () => {
    dispatch(snackbarAction.resetSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isOpen}
      autoHideDuration={infinity ? 999999999 : 5000} // infinity가 true일 경우 autoHideDuration을 null로 설정
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      action={infinity ? null : undefined} // `infinity`가 true일 때, 사라지지 않게 action 설정
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
