import { takeLatest, put, all, fork, select, delay } from "redux-saga/effects";
import * as carttrackingApi from "./carttrackingApi";
import * as smsApi from "../sms/smsApi";
import { carttrackingAction } from "./carttrackingSlice";
import Swal from "sweetalert2";

function* getCartTrackingListSaga(action) {
  try {
    const { userToken, params, page, size } = action.payload;
    // console.log(action.payload);
    // return;
    const response = yield carttrackingApi.getCartTrackingListApi({
      userToken,
      params,
    });
    if (response.status_code === 1) {
      yield put(carttrackingAction.getCartTrackingListSuccess(response.data));
    } else {
      yield put(carttrackingAction.getCartTrackingListError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getCartTrackingDetailSaga(action) {
  try {
    const { userToken, type, id, page, size } = action.payload;
    const response = yield carttrackingApi.getCartTrackingDetailApi({
      userToken,
      type,
      id,
      page,
      size,
    });
    console.log(action.payload);
    if (response.status_code === 1) {
      yield put(carttrackingAction.getCartTrackingDetailSuccess(response.data));
      if (response.data.content.length === 0) {
        Swal.fire({
          icon: "info",
          text: "조회된 데이터가 없습니다.",
        });
        return;
      }
      if (type === "customer") {
        yield put(carttrackingAction.setCartCustomerListPopup({ open: true, data: id }));
      } else {
        yield put(carttrackingAction.setCartProductListPopup({ open: true, data: id }));
      }
    } else {
      yield put(carttrackingAction.getCartTrackingDetailError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* exportCartTrackingListSaga(action) {
  try {
    const { userToken, searchType, searchValue, orderStatus } = action.payload;
    const response = yield carttrackingApi.exportExcelCartTrakingApi({
      userToken,
      orderStatus,
      searchType,
      searchValue,
    });
    if (response) {
      yield put(carttrackingAction.exportCartTrackingListSuccess(response.data));
    } else {
      yield put(carttrackingAction.exportCartTrackingListError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}
function* exportCartTrackinDetailListSaga(action) {
  try {
    const { userToken, searchType, orderStatus, type, id } = action.payload;
    const response = yield carttrackingApi.exportExcelCartTrackingDetailApi({
      userToken,
      orderStatus,
      type,
      searchType,
      id,
    });
    if (response.status_code === 1) {
      yield put(carttrackingAction.exportCartTrackinDetailListSuccess(response.data));
    } else {
      yield put(carttrackingAction.exportCartTrackinDetailListError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* sendCartSmsSaga(action) {
  try {
    const { userToken, data } = action.payload;
    const response = yield smsApi.sendSmsApi({
      userToken,
      data,
      store_current_id: 0,
    });
    if (response.status_code === 1) {
      yield put(carttrackingAction.sendCartSmsSuccess(response.data));
      Swal.fire({
        icon: "success",
        title: "성공",
        text: "SMS 전송이 완료되었습니다. 전송결과는 SMS 발송내역에서 확인 가능합니다.",
      });
      yield put(carttrackingAction.setCartSmsModalOpen(false));
      yield put(
        carttrackingAction.setCartSmsModalData({
          sms_template_id: null,
          sms_ttl: "",
          sms_cnts: "",
          receiver_no_list: [],
          sms_send_type: "sms",
        })
      );
    } else {
      yield put(carttrackingAction.sendCartSmsError(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* watchGetCartTrackingList() {
  yield takeLatest(carttrackingAction.getCartTrackingList, getCartTrackingListSaga);
}

function* watchGetCartTrackingDetail() {
  yield takeLatest(carttrackingAction.getCartTrackingDetail, getCartTrackingDetailSaga);
}

function* watchExportCartTrackingList() {
  yield takeLatest(carttrackingAction.exportCartTrackingList, exportCartTrackingListSaga);
}

function* watchExportCartTrackingDetailList() {
  yield takeLatest(carttrackingAction.exportCartTrackinDetailList, exportCartTrackinDetailListSaga);
}

function* watchSendCartSms() {
  yield takeLatest(carttrackingAction.sendCartSms, sendCartSmsSaga);
}

export function* carttrackingSaga() {
  yield all([
    fork(watchGetCartTrackingList),
    fork(watchGetCartTrackingDetail),
    fork(watchExportCartTrackingList),
    fork(watchExportCartTrackingDetailList),
    fork(watchSendCartSms),
  ]);
}
