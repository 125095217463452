import axios from "axios";

export const getRecentTrackingListApi = async ({ userToken, searchParams }) => {
  const url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/views`;
  return axios
    .get(url, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
      },
      params: searchParams, // 🔹 searchParams를 추가
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error fetching tracking list:", err);
      throw err; // 🔹 에러를 던져서 호출하는 쪽에서 처리 가능하도록 함
    });
};

export const getRecentTrackingDetailListApi = async ({ userToken, searchParams }) => {
  const url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/views/products/${searchParams.product_id}`;
  return axios
    .get(url, {
      headers: {
        "Authorization-Play": `Bearer ${userToken}`,
        Accept: "application/json",
      },
      params: {
        page: searchParams.page,
        size: searchParams.size,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error fetching tracking list:", err);
      throw err; // 🔹 에러를 던져서 호출하는 쪽에서 처리 가능하도록 함
    });
};

// type: 0 인 전체 페이지에서 다운로드 하는것
export const exportExcelRecentTrakingApi = async ({
  userToken,
  type, // 0: 전체조회 strDate, endDate   1:검색조회 productId
  searchParams,
}) => {
  const url = `${process.env.NEXT_PUBLIC_PLAY_ADMIN_API_HOST}/admin/views/statics/download/excel/${type}`;
  return axios
    .post(
      url,
      {
        ...searchParams,
      },
      {
        responseType: "blob",
        headers: {
          "Authorization-Play": `Bearer ${userToken}`,
          Accept: "application/json",
        },
      }
    )
    .then((response) => {
      // `Content-Disposition`에서 파일명 추출
      const disposition = response.headers["content-disposition"];
      let fileName = "download.xlsx";
      if (disposition && disposition.includes("filename*=")) {
        const match = disposition.match(/filename\*=(?:UTF-8'')?(.+)/);

        if (match?.[1]) {
          fileName = decodeURIComponent(match[1]); // UTF-8 디코딩
        }
      }

      // Blob을 생성하고 URL을 만들어 자동 다운로드 실행
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      return true;
    })
    .catch((err) => {
      console.error("파일 다운로드 실패", err);
      return false;
    });
};
