import {
  getClayfulUser,
  getClayfulUserCoupons,
  pushClayfulUserCoupons,
} from "@store/clayful/clayfulApi";
import { postFulfillmentItem } from "@store/order/orderApi";
import { postUserPointPlusOrMinus } from "@store/pointBackend/pointBackendApi";
import { jwtDecode } from "jwt-decode";
import { getStore, store } from "@store/configureStore";

/**
 *
 * @param {Array} arr - The array of items to be processed.
 * @param {number} chunkSize - The number of items to be processed in each chunk.
 * @returns {Promise<{successed: Array, failed: Array, resultIds: Array}>} - An object containing arrays of successful, failed, and result IDs.
 */
export const chunkRequest = async (arr, chunkSize) => {
  let successed = [];
  let failed = [];
  let resultIds = [];

  function chunkArray(arr, size) {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const chunkedArr = chunkArray(arr, chunkSize);

  for (let chunk of chunkedArr) {
    // 각 청크에 대해 병렬로 요청을 보냄
    const promises = chunk.map((item) => {
      return postFulfillmentItem({
        orderId: item.orderId,
        items: item.items,
        tracking: {
          company: null,
          uid: null,
          url: null,
        },
      })
        .then((response) => {
          return { status: response.status, orderId: item.orderId, responseId: response.data._id };
        })
        .catch((error) => ({
          status: error.response ? error.response.status : 500,
          orderId: item.orderId,
        }));
    });

    // 병렬로 요청을 보내고 결과를 기다림
    const results = await Promise.all(promises);

    // 결과를 successed와 failed 배열에 분류
    results.forEach((result) => {
      if (result.status === 200) {
        successed.push(result.orderId);
        resultIds.push(result.responseId);
      } else {
        failed.push(result.orderId);
      }
    });

    // 2초간 대기
    await sleep(1000);
  }

  return { successed, failed, resultIds };
};

/**
 * @param {Object} payload
 * @param {Array} payload.excelData
 * @param {number} [payload.chunkSize=50]
 * @param {number} payload.amount
 * @param {number} payload.points
 * @param {string} payload.userToken
 * @param {string} payload.status
 * @param {string} payload.memo
 *
 * @returns {Object}
 */
export const chunkRequestPushPoint = async (payload, onProgress) => {
  const {
    arr = payload.excelData,
    chunkSize = 50,
    amount,
    points,
    userToken,
    status,
    memo,
  } = payload;

  let successed = [];
  let failed = [];

  function chunkArray(arr, size) {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const chunkedArr = chunkArray(arr, chunkSize);

  for (let chunk of chunkedArr) {
    // 각 청크에 대해 병렬로 요청을 보냄
    const promises = chunk.map((id) => {
      return postUserPointPlusOrMinus({
        userToken: userToken,
        data: {
          amount: amount,
          status: status,
          customer_id: id,
          memo: memo,
        },
      })
        .then((response) => {
          return { status: response.is_success, id: response.point_event.customer_id };
        })
        .catch((error) => ({ status: error.response ? error.response.status : 500 }));
    });

    // 병렬로 요청을 보내고 결과를 기다림
    const results = await Promise.all(promises);

    // 결과를 successed와 failed 배열에 분류
    results.forEach((result) => {
      if (result.status) {
        successed.push(result.id);
      } else {
        failed.push(result.id);
      }

      if (onProgress) {
        onProgress(successed.length + failed.length);
      }
    });

    // 2초간 대기
    await sleep(1000);
  }

  return { successed, failed };
};
/**
 * @param {string[]} arr
 * @param {number} chunkSize
 * @param {string} userToken
 * @param {string[]} coupons
 */
export const chunkRequestPushCoupons = async (payload, onProgress) => {
  const {
    coupons,
    arr = payload.excelData,
    userToken,
    chunkSize = process.env.NODE_ENV === "production" ? 50 : 5, // dev = 5 prod = 30
  } = payload;

  let totalResult = [];

  function chunkArray(arr, size) {
    const chunkedArr = [];
    for (let i = 0; i < arr.length; i += size) {
      chunkedArr.push(arr.slice(i, i + size));
    }
    return chunkedArr;
  }

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const chunkedArr = chunkArray(arr, chunkSize);
  for (let chunk of chunkedArr) {
    const promises = chunk.map(async (id) => {
      let promiseResults = [];
      // 1. 여기서 id로 조회를 해 쿠폰을 가져 온다.
      const res = await getClayfulUserCoupons(id);
      // 현재 사용자가 없는 쿠폰만 필터링
      const filterdCoupons = coupons.split(",").filter((c) => {
        if (res.map((d) => d._id).includes(c)) {
          promiseResults.push({ status: false, id: id, coupon: c, etc: "해당 쿠폰 보유중" });
          return false;
        } else {
          return true;
        }
      });
      for (let i = 0; i < filterdCoupons.length; i++) {
        const push = await pushClayfulUserCoupons(id, filterdCoupons[i])
          .then((response) => response)
          .catch((err) => {
            promiseResults.push({
              status: false,
              id: id,
              coupon: filterdCoupons[i],
              etc: `요청에러(pushClayfulCoupon)`,
            });
          });
        if (push) {
          promiseResults.push({ status: true, id: id, coupon: filterdCoupons[i] });
        } else {
          promiseResults.push({
            status: false,
            id: id,
            coupon: filterdCoupons[i],
            etc: "요청에러(no push data)",
          });
        }
      }
      return promiseResults;
    });

    const results = await Promise.allSettled(promises);
    results.forEach((result) => {
      totalResult = result.status === "fulfilled" ? [...totalResult, ...result.value] : totalResult; // rejected된 경우에도 totalResult는 유지
    });

    // 진행도를 모든 result에 대해 증가
    if (onProgress) {
      onProgress(totalResult.length);
    }

    // 2초간 대기
    await sleep(1500);
  }
  return totalResult;
};

export function getTimeGap(pastTimeStr) {
  const now = new Date();
  const pastTime = new Date(pastTimeStr);

  const diff = now - pastTime;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}일 ${hours}시간 ${minutes}분 전`;
  }
  if (hours > 0) {
    return `${hours}시간 ${minutes}분 전`;
  } else {
    return `${minutes}분 전`;
  }
}

export function isAdmin(userToken) {
  if (!userToken) {
    return false;
  }

  const decoded = jwtDecode(userToken);
  console.log(decoded.storeInfo?.id === 1 ? "어드민 계정" : "일반 계정");
  return decoded.storeInfo?.id === 1 ? true : false;
}
